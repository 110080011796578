import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "productItem",
    class: {
      hot: _vm.productInfo.hot && !_vm.productInfo.firstDiscount && !_vm.productInfo.pin,
      firstDiscount: _vm.productInfo.firstDiscount && _vm.isFirstRechargeUser && !_vm.productInfo.pin,
      disabled: _vm.disabled,
      active: _vm.active
    },
    attrs: {
      "data-hotTips": _vm.productInfo.firstDiscount && _vm.isFirstRechargeUser ? _vm.isFirst : _vm.productInfo.hotTips
    },
    on: {
      click: function click($event) {
        return _vm.$emit("click", $event);
      }
    }
  }, [_c("em", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.productInfo.pin && !_vm.isBatch,
      expression: "productInfo.pin && !isBatch"
    }],
    staticClass: "pinMi"
  }, [_vm._v("充值卡")]), _c("em", [_vm._v(_vm._s(_vm.productInfo.name) + " "), _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips > 0 && _vm.productInfo.tips < 99998 && _vm.productInfo.unitValidity == "HOUR" ? _c("b", [_vm._v("(" + _vm._s(_vm.productInfo.tips) + "小时)")]) : _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips > 0 && _vm.productInfo.tips < 99998 && _vm.productInfo.unitValidity == "DAY" ? _c("b", [_vm._v("(" + _vm._s(_vm.productInfo.tips) + "天)")]) : _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips > 0 && _vm.productInfo.tips < 99998 && _vm.productInfo.unitValidity == "WEEK" ? _c("b", [_vm._v("(" + _vm._s(_vm.productInfo.tips) + "周)")]) : _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips > 0 && _vm.productInfo.tips < 99998 && _vm.productInfo.unitValidity == "MONTH" ? _c("b", [_vm._v("(" + _vm._s(_vm.productInfo.tips) + "月)")]) : _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips > 0 && _vm.productInfo.tips < 99998 && _vm.productInfo.unitValidity == "YEAR" ? _c("b", [_vm._v("(" + _vm._s(_vm.productInfo.tips) + "年)")]) : _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips == -1 ? _c("b", [_vm._v("(用完即止)")]) : _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips == 99999 ? _c("b", [_vm._v("(套餐有效期)")]) : _vm.type === "LL" && _vm.productInfo.tips && _vm.productInfo.tips == 0 ? _c("b", [_vm._v("(Sim卡有效期)")]) : _vm._e()]), _vm.productInfo.price ? _c("p", {
    class: {
      grayLineThrough: _vm.productInfo.firstDiscount && _vm.isFirstRechargeUser
    }
  }, [_vm._v(" 售价" + _vm._s(_vm._f("toFixed2")(_vm.productInfo.price)) + "元 ")]) : _vm._e(), _vm.productInfo.firstDiscount && _vm.isFirstRechargeUser && _vm.productInfo.price ? _c("p", {
    class: {
      redFont: _vm.productInfo.firstDiscount && _vm.isFirstRechargeUser
    }
  }, [_vm._v(" 首单价" + _vm._s(_vm._f("toFixed2")(_vm.productInfo.firstPrice)) + "元 ")]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };