var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "pointItem"
  }, [_c("div", {
    staticClass: "pointItem-pointInfo"
  }, [_c("em", [_vm._v(_vm._s(_vm.info.goodsName || _vm.info.source))]), _c("p", [_vm._v(_vm._s(_vm.info.created))])]), _c("p", {
    staticClass: "pointItem-pointNumber"
  }, [_vm._v(_vm._s(_vm.operation === "ADD" ? "+" : "-") + _vm._s(_vm.info.amount))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };