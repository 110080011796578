import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/lazyload/style";
import _Lazyload from "vant/es/lazyload";
Vue.use(_Swipe).use(_SwipeItem).use(_Lazyload);
export default {
  data: function data() {
    return {};
  },
  components: {},
  props: {
    images: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  created: function created() {},
  methods: {}
};