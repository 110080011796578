import "core-js/modules/es.object.keys.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "order-complaint"
    }
  }, [_c("div", {
    staticClass: "header"
  }, [_vm._v(" 24小时内为您解决该订单问题 ")]), _c("div", {
    staticClass: "complaint-box"
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "content"
  }, [_vm._m(0), _c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("游全球")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v("-￥" + _vm._s(_vm.orderInfo.payPrice))])]), _c("div", {
    staticClass: "guanzhu",
    on: {
      click: function click($event) {
        _vm.isShowDetail = !_vm.isShowDetail;
      }
    }
  }, [_vm._v(" 详情 "), !_vm.isShowDetail ? _c("i", {
    staticClass: "iconfont icon-xiajiantou"
  }) : _c("i", {
    staticClass: "iconfont icon-shangjiantou"
  })])])]), _c("div", {
    staticClass: "order-info",
    staticStyle: {
      padding: "0 20px"
    },
    style: {
      display: _vm.isShowDetail ? "block" : "none"
    }
  }, [_c("div", {
    staticClass: "order-table",
    attrs: {
      rfe: "content"
    }
  }, [_c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("交易编号 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.orderInfo.outTradeNo) + " ")])]), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("充值日期 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.orderInfo.created) + " ")])]), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("充值号码 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.orderInfo.account) + " ")])])])]), _c("div", {
    staticClass: "link"
  }, [_vm._m(1), _c("a", {
    staticClass: "link-btn",
    on: {
      click: _vm.toKeFu
    }
  }, [_c("i", {
    staticClass: "iconfont icon-kefu1",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-2px",
      "margin-right": "4px"
    }
  }), _vm._v(" 联系客服 ")])])]), _c("div", {
    staticClass: "options"
  }, [_c("div", {
    staticClass: "options-title"
  }, [_vm._v(" 选择你遇到的问题 ")]), _c("div", {
    staticStyle: {
      height: "5px",
      "background-color": "#f9f9f9",
      "margin-top": "20px"
    }
  }), _c("van-form", {
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-radio-group", {
    model: {
      value: _vm.radio,
      callback: function callback($$v) {
        _vm.radio = $$v;
      },
      expression: "radio"
    }
  }, [_c("van-cell-group", _vm._l(_vm.options, function (item, index) {
    return _c("van-cell", {
      attrs: {
        icon: item.icon,
        title: item.title,
        clickable: ""
      },
      on: {
        click: function click($event) {
          _vm.radio = item.value;
        }
      },
      scopedSlots: _vm._u([{
        key: "right-icon",
        fn: function fn() {
          return [_c("van-radio", {
            attrs: {
              name: item.value
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1), _c("van-cell-group", [_c("van-field", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.radio == "7",
      expression: "radio=='7'"
    }],
    attrs: {
      autosize: "",
      rules: [{
        validator: _vm.validator,
        message: ""
      }],
      placeholder: "请输入其他问题",
      type: "textarea"
    },
    model: {
      value: _vm.other,
      callback: function callback($$v) {
        _vm.other = $$v;
      },
      expression: "other"
    }
  }), _c("van-field", {
    attrs: {
      required: "",
      rules: [{
        required: true,
        message: ""
      }],
      type: "tel",
      label: "手机号",
      placeholder: "请输入手机号/微信号/邮箱号"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "info",
      "native-type": "submit"
    },
    on: {
      click: _vm.alertInfo
    }
  }, [_vm._v("提交")])], 1)], 1)], 1)]), _c("van-dialog", {
    attrs: {
      "close-on-click-overlay": "",
      title: "投诉详情"
    },
    on: {
      confirm: _vm.toOrderDetail
    },
    model: {
      value: _vm.showComplainDetailVisiable3,
      callback: function callback($$v) {
        _vm.showComplainDetailVisiable3 = $$v;
      },
      expression: "showComplainDetailVisiable3"
    }
  }, [_vm.complainInfo !== null && Object.keys(_vm.complainInfo).length > 0 ? _c("div", {
    staticStyle: {
      "text-align": "center",
      padding: "20px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("投诉问题：")]), _vm._v(" " + _vm._s(_vm.complainInfo.reason))]), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("投诉状态：")]), _vm._v(" " + _vm._s(_vm.complainStatus(_vm.complainInfo.status).text))]), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("客服回复：")]), _vm._v(" " + _vm._s(_vm.complainInfo.serverReply))])]) : _vm._e()])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/src/assets/logo.jpg",
      alt: "logo"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a", {
    staticClass: "link-btn",
    attrs: {
      href: "tel:057128350809"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-24gf-telephone",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-2px",
      "margin-right": "4px"
    }
  }), _vm._v(" 联系商家 ")]);
}];
render._withStripped = true;
export { render, staticRenderFns };