var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "serveDead"
    }
  }, [_c("h1", [_vm._v("自助充值服务升级中 !")]), _c("div", {
    staticClass: "dead-img"
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../../assets/serveDead/kefu.png"),
      alt: "系统维护中"
    }
  })]), _c("h2", [_c("div", [_vm._v("急需充值请扫码添加官方客服微信")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };