import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import requestWithLoading from '@/utils/requestWithLoading';
import axios from 'axios';
export function getPageData() {
  return requestWithLoading({
    url: '/wx/country/getAll',
    method: 'get'
  });
}
export function getUsedPhoneList() {
  return requestWithLoading({
    url: '/wx/user/msisdnList',
    method: 'get'
  });
}
// 清除用户充值过的号码记录
export function deleteUserMsisdnRecords(id) {
  return requestWithLoading({
    url: "/wx/user/userMsisdnRecords?id=".concat(id),
    method: 'post'
  });
}

// 根据 msisdn 删除用户的充值记录
export function delelteUserMsisdnRecord(id) {
  return requestWithLoading({
    url: "/wx/user/delete/userMsisdnRecord/".concat(id),
    method: 'post'
  });
}

// 删除用户的所有充值记录
export function delelteUserMsisdnRecordAll() {
  return requestWithLoading({
    url: "/wx/user/delete/allUserMsisdnRecord",
    method: 'post'
  });
}
export function deleteUserMsisdnRecordsAll() {
  return requestWithLoading({
    url: '/wx/user/userMsisdnRecords',
    method: 'delete'
  });
}
export function getCarrierInfo(_ref) {
  var account = _ref.account,
    countryCode = _ref.countryCode;
  return requestWithLoading({
    url: "/wx/carrier/getCarrierInfo/".concat(countryCode),
    method: 'POST',
    data: account
  });
}
export function getCarrierList(countryCode) {
  return requestWithLoading({
    url: "/wx/carrier/getCarriers/".concat(countryCode),
    method: 'get'
  });
}
export function getProductList(data) {
  return requestWithLoading({
    url: '/wx/product/getProduct',
    method: 'post',
    data: data
  });
}
export function getPreOrderInfo(data) {
  return requestWithLoading({
    url: '/wx/charge/preOrder',
    method: 'post',
    data: data
  });
}
// 批量充值
export function getPreOrderBatch(data) {
  return requestWithLoading({
    url: '/wx/charge/batch/order',
    method: 'post',
    data: data
  });
}

//检查黑名单号码
export function checkMobile(mobile, id) {
  return requestWithLoading({
    url: "/wx/charge/checkMobile/".concat(mobile, "?productId=").concat(id),
    method: 'get'
  });
}
//查询活动
export function getActive(data) {
  return requestWithLoading({
    url: '/wx/indexActivity/query',
    method: 'post',
    data: data
  });
}
//获取单个国家的信息
// export function getCountryInfoByCC(cc) {
//   return requestWithLoading({
//     url:`/wx/country/getCountry/${cc}`,
//     method:"get"
//   })
// }

//优化的首页数据
export function fastCountryInfoAndVerify(lastModify) {
  return requestWithLoading({
    url: "/wx/country/fastCountryInfoAndVerify?lastModify=".concat(lastModify),
    method: "get"
  });
}
//获取CDN上的国旗数据
export function getAllFlags() {
  return new Promise(function (resolve, reject) {
    axios({
      url: "https://oss.globalcharge.cn/prod/wechat/jsonInfo/flag.json",
      method: "get"
    }).then(function (res) {
      resolve(res);
    }).catch(function (err) {
      reject(err);
    });
  });
}
export function getCountryInfoByCC(CC) {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(process.env.VUE_APP_OSSURL, "/singleCountryInfo/").concat(CC, ".json"),
      method: 'get'
    }).then(function (res) {
      res.data = JSON.parse(decodeURIComponent(res.data));
      console.log(res);
      resolve(res);
    }).catch(function (err) {
      reject(err);
    });
  });
}

//获取国家警示弹框信息
export function getCountryAlertInfo(countryCode) {
  return requestWithLoading({
    url: "/wx/country/alert/".concat(countryCode),
    method: "get"
  });
}
export function getUserInfo() {
  return requestWithLoading({
    url: '/wx/user/info',
    method: 'get'
  });
}

//单笔充值详情
export function getOrderDetail(outTradeNo) {
  return requestWithLoading({
    url: "/wx/order/unifiedDetail/".concat(outTradeNo),
    method: 'get'
  });
}
//获取交易凭证
export function getOrderCertificate(outTradeNo, language) {
  return requestWithLoading({
    url: "/wx/order/receipt/".concat(outTradeNo),
    method: 'get',
    params: {
      language: language
    }
  });
}

//获取微信外部浏览器跳转微信小程序短链，有效期30天，过期需要重新请求
export function getUrlLink(appId, data) {
  return requestWithLoading({
    url: "/wechat/mini/generateUrlLink/".concat(appId),
    method: 'post',
    data: data
  });
}

//(用户是否关注公众号)
export function getUserIsSubscribe() {
  return requestWithLoading({
    url: "/wx/user/isWxOfficeSubscribe",
    method: 'get'
  });
}

//根据id查询运营商的查询方式信息
export function getQueryInfo(carrierId) {
  return requestWithLoading({
    url: "/wx/carrier/getCarrierQuery/".concat(carrierId),
    method: 'get'
  });
}

//获取兑换流量产品信息
export function getExchangeProduct(data) {
  return requestWithLoading({
    url: "/wx/product/getExchangeProduct",
    method: 'post',
    data: data
  });
}

//获取国家对应的banner列表信息
export function getBanners(countryCode) {
  var source = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'H5';
  return requestWithLoading({
    url: "/wx/banner/list/".concat(countryCode, "?source=").concat(source),
    method: 'get'
  });
}

// 用户是否 首充用户 (用户权限的接口，只有登陆的用户才允许查询)
export function selectWhetherNoChargedUser() {
  return requestWithLoading({
    url: "/wx/order/whetherNoChargedUser",
    method: 'get'
  });
}

// 用户状态接口
export function getUserStatusAll() {
  return requestWithLoading({
    url: "/wx/user/currentState",
    method: 'get'
  });
}

// 请求图文信息llStudyNewsList
export function getLlStudyNewsList(llStudyNewsId) {
  return requestWithLoading({
    url: "/wx/news/get/".concat(llStudyNewsId),
    method: 'get'
  });
}

// 根据国家请求图文列表getImageAndTextList
export function getImageAndTextList(countryCode) {
  return requestWithLoading({
    url: "/wx/news/countryList/".concat(countryCode),
    method: 'get'
  });
}