var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "imgContainer"
  }, [_c("img", {
    staticClass: "img",
    attrs: {
      src: _vm.imgUrl,
      alt: "支付成功"
    }
  })]), _c("div", {
    staticClass: "paySuccess"
  }, [_vm._v("购买成功!")]), _c("div", {
    staticClass: "tagContainer"
  }, [_c("div", {
    staticClass: "tagFont"
  }, [_vm._v("订单编号: "), _c("p", {
    staticClass: "tagP"
  }, [_vm._v(_vm._s(_vm.orderId))])]), _c("div", {
    staticClass: "tagFont"
  }, [_vm._v("卡密单号: "), _c("p", {
    staticClass: "tagP"
  }, [_vm._v(_vm._s(_vm.pinID))])]), _c("div", {
    staticClass: "tagFont"
  }, [_vm._v("购买时间: "), _c("p", {
    staticClass: "tagP"
  }, [_vm._v(_vm._s(_vm.time))])])]), _c("div", {
    staticClass: "btnContainer",
    on: {
      click: _vm.submit
    }
  }, [_c("div", {
    staticClass: "btn"
  }, [_vm._v("确认")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };