import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "core-js/modules/es.function.name.js";
import ZchIcon from '@/components/ZchIcon';
export default {
  name: 'ZchCarrierList',
  components: _defineProperty({
    ZchIcon: ZchIcon
  }, _Popup.name, _Popup),
  props: {
    carrierVisible: Boolean,
    carrierList: Array,
    selectedCarrierName: String,
    activeTab: String,
    getProductList: Function,
    removeProductListActiveIndex: Function
  },
  computed: {
    _carrierVisible: {
      set: function set(value) {
        this.$emit('close', value);
      },
      get: function get() {
        return this.carrierVisible;
      }
    }
  }
};