var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "shareFriends"
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: require("@@/userCenter/shareHeader2.png"),
      alt: "邀好友 得积分"
    }
  }), _c("div", {
    staticClass: "contentWrapper"
  }, [_c("div", {
    staticClass: "shareTipsWrapper"
  }, [_c("div", {
    staticClass: "shareButtonBar",
    class: {
      shareButtonBar2: !_vm.aliPayLife && !_vm.wxBrowser
    }
  }, [_c("p", [_c("a", {
    on: {
      click: function click($event) {
        _vm.ruleVisible = true;
      }
    }
  }, [_vm._v("规则")])])]), _c("div", {
    staticClass: "shareTips"
  }, [_c("img", {
    staticStyle: {
      "padding-left": "10px"
    },
    attrs: {
      width: "100%",
      src: require("@@/userCenter/shareTips2.png"),
      alt: "邀请三步骤"
    }
  }), _c("div", {
    staticClass: "invite"
  }, [_c("div", {
    staticClass: "invite-left",
    on: {
      click: _vm.inviteFriends
    }
  }, [_vm._v("立即邀请好友")]), _c("div", {
    staticClass: "invite-right",
    on: {
      click: _vm.createdImg
    }
  }, [_vm._v("生成美图")])])]), _c("div", {
    staticClass: "shareTips shareTips2"
  }, [_c("div", {
    staticClass: "shareTips2-title"
  }, [_vm._v("我的成就")]), _c("div", {
    staticClass: "shareTips2-body"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("已成功邀请")]), _c("div", {
    staticClass: "num"
  }, [_c("span", {
    staticClass: "num1"
  }, [_vm._v(_vm._s(_vm.static.people || 0))]), _vm._v(" "), _c("span", {
    staticClass: "num2"
  }, [_vm._v("人")])])]), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("已获得奖励 ")]), _c("div", {
    staticClass: "num"
  }, [_c("span", {
    staticClass: "num1"
  }, [_vm._v(_vm._s(_vm.static.totalPoints || 0))]), _vm._v(" "), _c("span", {
    staticClass: "num2"
  }, [_vm._v("积分")])])])]), _c("span", {
    staticClass: "toShop",
    on: {
      click: _vm.toIndex
    }
  }, [_vm._v("去充值")]), _c("div", {
    staticClass: "shareTips2-record"
  }, [_c("div", {
    on: {
      click: _vm.toShareRecord
    }
  }, [_vm._v("查看我的邀请记录")])])])]), _vm.showMask ? _c("div", {
    staticClass: "mask"
  }, [_c("img", {
    staticClass: "arrow",
    attrs: {
      src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAACICAMAAABQgAwUAAAAMFBMVEUAAADY2NjY2NjY2NjY2NjY2NjY2NjY2NjX19fY2NjY2NjY2NjY2NjY2NjY2NjY2Njr/TvvAAAAD3RSTlMAxy89c9CdTRyG7lvcD7FzqbJAAAACFklEQVR42uWYy4rkMBAErZdlPdzx/3+7LAw0tH0Y2orDsnnyKQlSVaWytoc6xrEpigFoinUAIBnWABAE5woW9o6GPbGwI1jYGSzsgoV9goU9wMLe0bA7FnYCC7uBhV2wsE+wsAdY2AENGyzsBBZ2Q8MuWNgH94pLbgELO6Bhg4VdwcJuaNgTCzuChZ3Bwg5o2GBhV7CwdzTsjoUdwcLOYGEXLOwTLOwBFvaOht2xsBNY2I1f6lhaenvhrfpkAblab+k9b/OD0iuX2F9/x8D+7ZL2pmpbuj+6o3Vg//oWmPU9p65VkXL6+oIJ8S738nwj62Pb1lvHACH+fBs7sG59U3yrVD3rce3GVcp8qGkPAGTprQUYy6xfaE8i82b6S7/pfZnzdYQIHeOXdfYKpHoFcmrvWlM8RW+CDO8JMWoNM/+FeyB4UfMpL48g5qG1Iqc29YI3mqq2knXvEJu2onJoQy9ok4mkQZf/GjqitUvQyqN6SU8NOvOhHq25xNCWj6LFQdLiyKuaZWpxBC2OrFVHxdryElbQsVtBx6KN0qAd4a71yo610uxa2b0s5xg052I5p26d4MCqusZFwzrAnqQhSogSMnkNcr+GUS3kEKWS62NJFlNCToWLZpWMe14RReGqdjz2PfNECbkGbrQ/Nj5q5y7j8/HRTW5UhvHfA7Mdzitji8rfWsgX3gVZ91eO22odKed6LLf9A/sRnc74RV7lAAAAAElFTkSuQmCC",
      alt: ""
    }
  }), _vm._m(0), _c("div", {
    staticClass: "know",
    on: {
      click: _vm.closeMask
    }
  }, [_vm._v("我知道了")])]) : _vm._e()]), _c("van-dialog", {
    attrs: {
      title: "",
      "confirm-button-text": "↑↑↑长按上图保存到相册",
      "show-confirm-button": false,
      "close-on-click-overlay": ""
    },
    model: {
      value: _vm.showQrCodeVisiable,
      callback: function callback($$v) {
        _vm.showQrCodeVisiable = $$v;
      },
      expression: "showQrCodeVisiable"
    }
  }, [_vm.testUrl == "" ? _c("div", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      id: "container"
    }
  }, [_c("img", {
    staticClass: "bgImg",
    staticStyle: {
      width: "320px",
      height: "500px"
    },
    attrs: {
      src: _vm.qrBg + "?_" + Date.now(),
      crossOrigin: "anonymous",
      alt: "背景图"
    },
    on: {
      load: function load($event) {
        return _vm.getImg();
      }
    }
  }), _c("img", {
    staticClass: "qrImg",
    attrs: {
      src: _vm.imgUrl,
      alt: "二维码",
      crossOrigin: "anonymous"
    }
  })]) : _vm._e(), _vm.testUrl ? _c("div", [_c("img", {
    staticStyle: {
      width: "320px",
      height: "500px"
    },
    attrs: {
      src: _vm.testUrl,
      alt: ""
    }
  }), _c("div", {
    staticClass: "save"
  }, [_vm._v("↑↑↑长按上图保存到相册")])]) : _vm._e()]), _c("van-dialog", {
    attrs: {
      title: "拉新规则",
      "confirm-button-text": "确认"
    },
    model: {
      value: _vm.ruleVisible,
      callback: function callback($$v) {
        _vm.ruleVisible = $$v;
      },
      expression: "ruleVisible"
    }
  }, [_c("div", {
    staticClass: "rule-box"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 积分说明： ")]), _c("p", [_vm._v("好友关注并充值即为有效用户，支付金额=您将获得相应积分。")]), _c("p", [_vm._v("奖励积分=支付金额*100%。")]), _c("p", [_vm._v("* 积分可在充值时直接抵扣(100积分=1元)")]), _c("p", [_vm._v("* 有效时间：从好友关注起180天")])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "desc"
  }, [_c("p", [_vm._v("1.点击右上角")]), _c("p", [_vm._v("2.点击分享给好友或分享至朋友圈")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };