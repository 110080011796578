export default {
  name: 'ShareQrCode',
  data: {
    shareQrCodeImg: '',
    myEndTime: ''
  },
  created: function created() {
    this.shareQrCodeImg = localStorage.getItem('shareQrCodeImg');
    this.myEndTime = this.getDateByType(+new Date() + 2592000000);
  },
  methods: {
    getDateByType: function getDateByType(time) {
      var date = new Date(time);
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = '0' + mymonth;
      }
      if (myweekday < 10) {
        myweekday = '0' + myweekday;
      }
      // if (type === "year") {
      //   return myyear;
      // } else if (type === "month") {
      //   return myyear + '-' + mymonth;
      // }
      return myyear + '.' + mymonth + '.' + myweekday;
    }
  }
};