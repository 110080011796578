var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("van-popup", {
    staticClass: "waitOrderPopup",
    attrs: {
      "safe-area-inset-bottom": "",
      "close-on-click-overlay": false,
      closeable: true,
      "close-on-popstate": true,
      "get-container": "#app"
    },
    model: {
      value: _vm._waitOrderVisible,
      callback: function callback($$v) {
        _vm._waitOrderVisible = $$v;
      },
      expression: "_waitOrderVisible"
    }
  }, [_c("p", {
    staticClass: "waitOrderPopup-title"
  }, [_vm._v("你有一笔待支付订单")]), _c("div", {
    staticClass: "waitOrderPopup-content"
  }, [_c("div", {
    staticClass: "waitOrderPopup-item"
  }, [_c("label", [_vm._v("订单详情：")]), _c("pre", [_vm._v(_vm._s(_vm.waitOrderInfo.body))])]), _c("div", {
    staticClass: "waitOrderPopup-item"
  }, [_c("label", [_vm._v("手机号码：")]), _c("span", [_vm._v(_vm._s(_vm.waitOrderInfo.account))])]), _c("div", {
    staticClass: "waitOrderPopup-item"
  }, [_c("label", [_vm._v("原价：")]), _c("span", [_vm._v("￥" + _vm._s(_vm._f("toFixed2")(_vm.waitOrderInfo.totalAmount / 100)))])]), _vm.waitOrderInfo.totalAmount - _vm.waitOrderInfo.payPrice !== 0 ? _c("div", {
    staticClass: "waitOrderPopup-item"
  }, [_c("label", [_vm._v("优惠：")]), _c("span", {
    staticClass: "waitOrderPopup-item-discount"
  }, [_vm._v("-￥" + _vm._s(_vm._f("toFixed2")((_vm.waitOrderInfo.totalAmount - _vm.waitOrderInfo.payPrice) / 100)) + "(" + _vm._s(_vm.showPendingOffer()) + ")")])]) : _vm._e(), _c("div", {
    staticClass: "waitOrderPopup-item"
  }, [_c("label", [_vm._v("支付金额：")]), _c("span", {
    staticClass: "waitOrderPopup-item-payPrice"
  }, [_vm._v("￥" + _vm._s(_vm._f("toFixed2")(_vm.waitOrderInfo.payPrice / 100)))])])]), _c("div", {
    staticClass: "waitOrderPopup-button"
  }, [_c("button", {
    on: {
      click: _vm.closeWaitOrder
    }
  }, [_vm._v("关闭订单")]), _c("button", {
    on: {
      click: _vm.payWaitOrder
    }
  }, [_vm._v("立即支付")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };