import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "lottery-con",
    attrs: {
      id: "lottery"
    }
  }, [_c("div", {
    staticClass: "lottery-header"
  }, [_c("p", [_vm._v("我的积分："), _c("em", [_vm._v(_vm._s(_vm.userPoints))])]), _c("router-link", {
    attrs: {
      to: "/mall/point"
    }
  }, [_vm._v("兑换奖励"), _c("zch-icon", {
    attrs: {
      icon: "right"
    }
  })], 1)], 1), _c("div", {
    staticClass: "lottery-body clearfix"
  }, [_c("div", {
    staticClass: "lottery-tool"
  }, [_c("div", {
    staticClass: "lottery-thead"
  }, [_vm._m(0), _c("span", {
    on: {
      click: function click($event) {
        _vm.lotteryRuleVisible = true;
      }
    }
  }, [_vm._v("抽奖规则"), _c("zch-icon", {
    attrs: {
      icon: "tips"
    }
  })], 1)]), _c("div", {
    staticClass: "lottery-tbody"
  }, [_c("div", {
    staticClass: "lottery-tb-inner",
    class: _vm.flopDisable.shuffle
  }, [_c("ul", {
    staticClass: "lottery-tb-list"
  }, _vm._l(_vm.newPrizeList, function (item, index) {
    return _c("li", {
      key: index,
      class: {
        bigPrize: _vm.isBigPrize(item)
      }
    }, [_c("div", {
      staticClass: "lottery-playing-cards viewport-flip",
      on: {
        click: function click($event) {
          return _vm.openPrize(index);
        }
      }
    }, [_c("div", {
      staticClass: "lottery-flip-card lottery-positive flip",
      class: {
        out: item.positiveOutAnimation,
        in: item.positiveInAnimation
      }
    }, [_c("img", {
      attrs: {
        width: "100%",
        src: item.picUrl,
        alt: item.name
      }
    }), !_vm.isBigPrize(item) ? _c("p", [_vm._v(_vm._s(item.description))]) : _vm._e()]), _c("div", {
      staticClass: "lottery-flip-card lottery-reverse flip",
      class: {
        out: item.reverseOutAnimation,
        in: item.reverseInAnimation
      }
    }, [_c("img", {
      attrs: {
        width: "100%",
        src: require("@@/artCenter/lottery/reverse.png"),
        alt: "翻牌"
      }
    })])])]);
  }), 0), _c("div", {
    staticClass: "lottery-tb-btn",
    class: {
      "lottery-tb-btn-d": _vm.touchButtonClass,
      disabled: _vm.flopBtnText !== "请翻牌" || _vm.flopNum <= 0 || _vm.userPoints < _vm.consumePointRule[_vm.flopNum]
    },
    attrs: {
      id: "flopBtn"
    },
    on: {
      touchstart: function touchstart($event) {
        _vm.touchButtonClass = true;
      },
      touchend: function touchend($event) {
        _vm.touchButtonClass = false;
      },
      click: _vm.startLottery
    }
  }, [_vm.userPoints > _vm.consumePointRule[_vm.flopNum] ? [_vm.flopNum > 0 ? _c("em", [_vm._v(_vm._s(_vm.consumePointRule[_vm.flopNum]) + "积分")]) : _vm._e(), _c("p", {
    staticClass: "scale"
  }, [_vm._v(_vm._s(_vm.flopBtnText))]), _c("small", [_vm._v("今日还可玩" + _vm._s(_vm.flopNum) + "次")])] : [_c("p", [_vm._v("积分不足")])]], 2), _c("div", {
    staticClass: "lottery-mask",
    class: {
      disabled: _vm.flopDisable.mask
    }
  })])])])]), _c("p", {
    staticClass: "myPrizeRecord"
  }, [_c("router-link", {
    attrs: {
      to: "/art/lottery-record"
    }
  }, [_vm._v("我的中奖记录"), _c("zch-icon", {
    attrs: {
      icon: "right"
    }
  })], 1)], 1), _c("div", {
    staticClass: "artBar"
  }, [_c("router-link", {
    attrs: {
      tag: "div",
      to: "/home"
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: require("@@/artCenter/lottery/lotteryArt2.png"),
      alt: "奖励1"
    }
  })]), _c("div", {
    on: {
      click: function click($event) {
        return _vm.Dialog({
          message: "活动暂未开始",
          title: "提示"
        });
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: require("@@/artCenter/lottery/lotteryArt1.png"),
      alt: "奖励2"
    }
  })])], 1), _c("van-popup", {
    staticClass: "resultPrize",
    attrs: {
      "close-on-click-overlay": false
    },
    model: {
      value: _vm.resultPrizeVisible,
      callback: function callback($$v) {
        _vm.resultPrizeVisible = $$v;
      },
      expression: "resultPrizeVisible"
    }
  }, [_c("h1", [_vm._v("恭喜抽中")]), _c("div", {
    staticClass: "prizeImg"
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.resultPrize.picUrl,
      alt: "奖品"
    }
  })]), _c("em", [_vm._v(_vm._s(_vm.resultPrize.description))]), _vm.resultPrize.type === "POINT" ? _c("p", [_vm._v("请在“个人中心-积分商城”内查看并使用")]) : _vm._e(), _vm.resultPrize.type === "COUPON" ? _c("p", [_vm._v("请在“个人中心-优惠券”内查看并使用")]) : _vm._e(), _vm.resultPrize.type === "NONE" ? _c("p", [_vm._v("很遗憾，啥也没抽中")]) : _vm._e(), _c("b", {
    on: {
      click: _vm.showResultPrizeList
    }
  }, [_vm._v("再来一次")])]), _c("van-popup", {
    staticClass: "pointError",
    attrs: {
      "close-on-click-overlay": false
    },
    model: {
      value: _vm.pointErrorVisible,
      callback: function callback($$v) {
        _vm.pointErrorVisible = $$v;
      },
      expression: "pointErrorVisible"
    }
  }, [_c("zch-point-error", {
    on: {
      click: function click($event) {
        _vm.pointErrorVisible = false;
      }
    }
  })], 1), _c("van-popup", {
    staticClass: "lotteryRule",
    attrs: {
      closeable: "",
      "close-on-click-overlay": false
    },
    model: {
      value: _vm.lotteryRuleVisible,
      callback: function callback($$v) {
        _vm.lotteryRuleVisible = $$v;
      },
      expression: "lotteryRuleVisible"
    }
  }, [_c("h5", [_vm._v("抽奖规则：")]), _c("h6", [_vm._v("1.活动时间")]), _c("p", [_vm._v("2020.1.1")]), _c("h6", [_vm._v("2.活动规则")]), _c("p", [_vm._v("活动期间，用户每天可获得一次免费抽奖机会，还可以通过使用积分进行抽奖，每日抽奖上限为3次。抽奖免费，抽奖机会每日刷新，随机出奖。")]), _c("p", [_vm._v("第一次：免费")]), _c("p", [_vm._v("第二次：10积分")]), _c("p", [_vm._v("第三次：20积分")]), _c("h6", [_vm._v("奖励：")]), _c("p", [_vm._v("3.奖励为：话费券 流量券 积分 免单券")])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_vm._v("天天抽奖"), _c("small", [_vm._v("每日免费抽奖1次。次数每日清空")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };