import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import requestWithLoading from '@/utils/requestWithLoading';

//单笔充值详情
export function getOrderDetail(outTradeNo) {
  return requestWithLoading({
    url: "/wx/order/unifiedDetail/".concat(outTradeNo),
    method: 'get'
  });
}
//获取交易凭证
export function getOrderCertificate(outTradeNo, language) {
  return requestWithLoading({
    url: "/wx/order/receipt/".concat(outTradeNo),
    method: 'get',
    params: {
      language: language
    }
  });
}
/**
 * 根据运营商id获取信息 pinQueryUrl
 */
export function getCarrierById(id) {
  return requestWithLoading({
    url: "/wx/carrier/get/".concat(id),
    method: 'get'
  });
}
export function orderPayAgain(data) {
  return requestWithLoading({
    url: '/wx/charge/again/pay',
    method: 'post',
    data: data
  });
}
export function getCountryInfoByCC(CC) {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(process.env.VUE_APP_OSSURL, "/singleCountryInfo/").concat(CC, ".json"),
      method: 'get'
    }).then(function (res) {
      res.data = JSON.parse(decodeURIComponent(res.data));
      console.log(res);
      resolve(res);
    }).catch(function (err) {
      reject(err);
    });
  });
}

//获取投诉信息
export function getComplaintInfo(productOrderId) {
  return requestWithLoading({
    url: "/wx/userComplain/get/".concat(productOrderId),
    method: 'GET'
  });
}

//用户关闭投诉
export function closeComplaint(productOrderId, resolved) {
  return requestWithLoading({
    url: "/wx/userComplain/close/".concat(productOrderId, "?resolved=").concat(resolved),
    method: 'POST'
  });
}
export function getUserInfo() {
  return requestWithLoading({
    url: '/wx/user/info',
    method: 'get'
  });
}

//(用户是否关注公众号)
export function getUserIsSubscribe() {
  return requestWithLoading({
    url: "/wx/user/isWxOfficeSubscribe",
    method: 'get'
  });
}
export function getCarrierInfo(carrierId, type) {
  return requestWithLoading({
    url: "/wx/carrier/getCarrierInfo?carrierId=".concat(carrierId, "&type=").concat(type),
    method: 'get'
  });
}
//根据id查询运营商的查询方式信息
export function getQueryInfo(carrierId) {
  return requestWithLoading({
    url: "/wx/carrier/getCarrierQuery/".concat(carrierId),
    method: 'get'
  });
}