var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "couponWrapper"
  }, [_c("h6", [_vm._v("选择优惠券")]), _vm.couponList.length === 0 ? _c("p", {
    staticClass: "emptyTips"
  }, [_vm._v("暂无优惠券")]) : _vm._e(), _c("van-radio-group", {
    attrs: {
      "checked-color": "#FC6E50",
      "icon-size": "26px"
    },
    model: {
      value: _vm.radio,
      callback: function callback($$v) {
        _vm.radio = $$v;
      },
      expression: "radio"
    }
  }, [_c("div", {
    staticClass: "couponList"
  }, _vm._l(_vm.couponList, function (item) {
    return _c("div", {
      key: item.uuid,
      staticClass: "couponList-item",
      on: {
        click: function click($event) {
          _vm.radio = item.uuid;
          _vm.$emit("useCoupon", item);
        }
      }
    }, [_c("item", {
      staticClass: "couponList-item-coupon",
      attrs: {
        "coupon-info": item
      }
    }), _c("van-radio", {
      staticClass: "couponList-item-radio",
      attrs: {
        slot: "right-icon",
        name: item.uuid
      },
      slot: "right-icon"
    })], 1);
  }), 0)]), _c("div", {
    staticClass: "notUseCoupon",
    on: {
      click: function click($event) {
        ;
        _vm.radio = null, _vm.$emit("useCoupon", {
          id: -1
        });
      }
    }
  }, [_vm._v("不使用优惠券")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };