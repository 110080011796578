import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=38a317eb&scoped=true"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"
import style0 from "./Search.vue?vue&type=style&index=0&id=38a317eb&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a317eb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38a317eb')) {
      api.createRecord('38a317eb', component.options)
    } else {
      api.reload('38a317eb', component.options)
    }
    module.hot.accept("./Search.vue?vue&type=template&id=38a317eb&scoped=true", function () {
      api.rerender('38a317eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Home/components/Search.vue"
export default component.exports