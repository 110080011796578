import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "userCenter"
    }
  }, [!_vm.aliPayLife && !_vm.wxBrowser ? _c("div", {
    staticClass: "nav"
  }, [_c("div", {
    staticClass: "nav-back",
    on: {
      click: function click($event) {
        return _vm.$router.push("/home");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-zuojiantou",
    staticStyle: {
      "font-size": "20px",
      "font-weight": "600"
    }
  })]), _c("div", {
    staticClass: "nav-title"
  }, [_vm._v(_vm._s(_vm.$route.meta.title))])]) : _vm._e(), _c("header", {
    staticClass: "userCard"
  }, [_c("div", {
    staticClass: "userCard-img"
  }, [_vm.userInfo.headImgUrl == "" || _vm.userInfo.headImgUrl == null ? _c("img", {
    staticStyle: {
      width: "56px",
      height: "56px"
    },
    attrs: {
      width: "100%",
      src: "https://oss.globalcharge.cn/prod/wechat/image/defaultHeadImg.png",
      alt: "头像"
    }
  }) : _c("img", {
    staticStyle: {
      width: "56px",
      height: "56px"
    },
    attrs: {
      width: "100%",
      src: _vm.userInfo.headImgUrl,
      alt: "头像"
    }
  })]), _c("div", {
    staticClass: "userCard-info"
  }, [_c("div", {
    staticClass: "userCard-info-name"
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.nickName || "昵称"))]), _vm.userInfo.member ? _c("span", [_vm._v("会员")]) : _vm._e()]), _c("router-link", {
    staticClass: "userCard-info-point",
    attrs: {
      tag: "div",
      to: "/user/point-record"
    }
  }, [_vm._v("我的积分：" + _vm._s(_vm.userInfo.point || "0") + "分")])], 1)]), _vm.subscribeOffice === false ? _c("div", {
    staticClass: "gongZhongHao",
    on: {
      click: _vm.toGongZhongHao
    }
  }, [_c("div", {
    staticClass: "title-desc"
  }, [_vm._v("游全球关联的公众号")]), _vm._m(0)]) : _vm._e(), _vm.subscribeOffice === true ? _c("div", {
    staticClass: "vipArtBar",
    on: {
      click: function click($event) {
        _vm.userInfo.member ? _vm.goToSharePoster() : _vm.createMember();
      }
    }
  }, [_c("div", {
    staticClass: "vipArtBar-info"
  }, [_c("p", [_c("zch-icon", {
    attrs: {
      icon: "crown"
    }
  }), _vm._v("游全球会员")], 1), _c("em", [_vm._v("积分抵扣现金权益，充值话费不花钱")])]), _c("div", {
    staticClass: "vipArtBar-button"
  }, [_vm.userInfo.member ? _c("zch-icon", {
    attrs: {
      icon: "qrcode"
    }
  }) : _c("p", [_vm._v("立即开通")])], 1)]) : _vm._e(), _vm.userInfo.member ? _c("img", {
    staticClass: "vipArtBanner",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      width: "100%",
      src: require("@@/userCenter/vipArtBanner.png"),
      alt: "积分当钱花"
    },
    on: {
      click: _vm.goToShareFriends
    }
  }) : _vm._e(), _vm.userInfo.member ? _c("div", {
    staticClass: "card pointCard"
  }, [_c("p", {
    staticClass: "card-title"
  }, [_c("em", [_vm._v("积分详情")]), _c("span", {
    attrs: {
      tag: "span"
    },
    on: {
      click: _vm.goToShareFriends
    }
  }, [_vm._v("如何获得积分？")])]), _c("ul", {
    staticClass: "card-body"
  }, [_c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/share-record"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.inviteFriend))]), _c("p", [_vm._v("邀请好友")])]), _c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/point-record?operation=DEDUCT"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.pointOfUsed))]), _c("p", [_vm._v("已使用积分")])]), _c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/point-record"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.pointOfTotal))]), _c("p", [_vm._v("累计积分")])])], 1)]) : _vm._e(), _c("div", {
    staticClass: "card rechargeCard"
  }, [_vm._m(1), _c("ul", {
    staticClass: "card-body"
  }, [_c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/coupon"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.couponNumber))]), _c("p", [_vm._v("优惠券")])]), _c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/recharge-record"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.chargeCount))]), _c("p", [_vm._v("充值记录")])]), _c("li", {
    staticClass: "card-body-item"
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.payAmount))]), _c("p", [_vm._v("消费金额")])]), _c("li", {
    staticClass: "card-body-item"
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.saveAmount))]), _c("p", [_vm._v("已省金额")])])], 1)]), !_vm.aliPayLife ? _c("div", {
    staticClass: "card serveCard"
  }, [_vm._m(2), _c("ul", {
    staticClass: "card-body"
  }, [_c("li", {
    staticClass: "card-body-item",
    on: {
      click: function click($event) {
        _vm.fansGroupVisible = true;
      }
    }
  }, [_c("em", [_c("zch-icon", {
    staticClass: "message_fill",
    attrs: {
      icon: "message_fill"
    }
  })], 1), _c("p", [_vm._v("加入海外粉丝群")])]), _c("li", {
    staticClass: "card-body-item",
    on: {
      click: _vm.toKeFu
    }
  }, [_c("em", [_c("zch-icon", {
    staticClass: "smile_fill",
    attrs: {
      icon: "smile_fill"
    }
  })], 1), _c("p", [_vm._v("联系客服")])])])]) : _vm._e(), _c("van-popup", {
    staticClass: "fansGroupPopup",
    model: {
      value: _vm.fansGroupVisible,
      callback: function callback($$v) {
        _vm.fansGroupVisible = $$v;
      },
      expression: "fansGroupVisible"
    }
  }, [_c("img", {
    staticStyle: {
      "border-radius": "10px",
      "max-width": "500px",
      "min-width": "280px"
    },
    attrs: {
      width: "100%",
      src: "https://oss.globalcharge.cn/prod/miniProgram/fansGroup.png",
      alt: "扫码加群"
    }
  })]), _c("van-popup", {
    staticClass: "miniprogramPopup",
    model: {
      value: _vm.miniprogramVisible,
      callback: function callback($$v) {
        _vm.miniprogramVisible = $$v;
      },
      expression: "miniprogramVisible"
    }
  }, [_c("zch-miniprogram-code")], 1), _c("footer", [_c("router-link", {
    attrs: {
      tag: "div",
      to: "/batch/recharge"
    }
  }, [_c("zch-icon", {
    class: {
      active: _vm.$route.name === "BatchRecharge"
    },
    attrs: {
      icon: "home"
    }
  })], 1), _c("router-link", {
    attrs: {
      tag: "div",
      to: "/batch/user"
    }
  }, [_c("zch-icon", {
    class: {
      active: _vm.$route.name === "BatchUser"
    },
    attrs: {
      icon: "user"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/src/assets/logo.jpg",
      alt: "logo"
    }
  })]), _c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("游全球")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v("关注公众号接收充值结果提醒，赠送优惠券&50充值积分")])]), _c("div", {
    staticClass: "guanzhu"
  }, [_vm._v("关注")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "card-title"
  }, [_c("em", [_vm._v("充值详情")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "card-title"
  }, [_c("em", [_vm._v("服务帮助")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };