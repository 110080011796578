var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "laedFollow"
    }
  }, [_c("img", {
    staticClass: "my-img",
    attrs: {
      src: require("../../assets/laedFollow/unSubscribe.jpg"),
      alt: "二维码"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };