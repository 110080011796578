var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "403Page"
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "middle-bar"
  }, [_c("strong", [_vm._v("没有访问权限")]), _c("p", [_vm._v("很抱歉，系统君拒绝了您的请求！")]), _c("router-link", {
    attrs: {
      to: "/"
    }
  }, [_c("van-button", {
    staticClass: "return-btn",
    attrs: {
      plain: "",
      hairline: "",
      type: "warning"
    }
  }, [_vm._v("返回首页")])], 1)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-bar"
  }, [_c("div", {
    staticClass: "img-403"
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: require("../../assets/403/403.png"),
      alt: "403"
    }
  })])]);
}];
render._withStripped = true;
export { render, staticRenderFns };