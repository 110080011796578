var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("button", [_vm._v("分享至朋友圈")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };