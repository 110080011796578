var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "login-btn"
    }
  }, [_c("div", {
    staticClass: "wx-btn",
    on: {
      click: _vm.wxAuthLogin
    }
  }, [_vm._v("微信登录")]), _c("div", {
    staticClass: "ali-btn",
    on: {
      click: _vm.aliAuthLogin
    }
  }, [_vm._v("支付宝登录")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };