var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {}, [[_vm.llStudyNewsList == null || _vm.llStudyNewsList.length == 0 ? _c("div") : _vm.llStudyNewsList ? _c("div", [_c("ImageTextAndArticleDetail", {
    key: _vm.llStudyNewsId,
    attrs: {
      llStudyNewsId: _vm.llStudyNewsId,
      llStudyNewsList: _vm.llStudyNewsList
    }
  })], 1) : _vm._e()]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };