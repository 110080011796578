import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import CouponList from './components/CouponList';
import { wxWebLogin } from './api.js';
import { sendCode } from '@/api/app.js';
import Cookies from 'js-cookie';
import { isWechatBrowser } from '../../../utils';
export default {
  name: 'UserCoupon',
  components: _defineProperty(_defineProperty({
    CouponList: CouponList
  }, _Tab.name, _Tab), _Tabs.name, _Tabs),
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    wxAuthLogin: function wxAuthLogin() {
      var appid = 'wx3422f429917be190';
      var redirectUrl = encodeURIComponent("https://lingxue.kqlink.com".concat(this.$route.path));
      var state = 'zch';
      var scope = 'snsapi_login';
      console.log(window.location.href);
      console.log(this.$route);
      // window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
    },
    aliAuthLogin: function aliAuthLogin() {
      var appid = '2016082601805806'; //这个是网页的appid
      // const appid = process.env.VUE_APP_ALI_APPID//生活号appid
      var redirectUrl = encodeURIComponent("https://lingxue.kqlink.com".concat(this.$route.path));
      console.log(redirectUrl, "重新跳转的路径。。");
      var scope = 'auth_user';
      window.location.href = "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=".concat(appid, "&scope=").concat(scope, "&redirect_uri=").concat(redirectUrl, "&state=zch");
    }
  }
};