import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  name: "ProductItem",
  props: {
    type: String,
    productInfo: Object,
    disabled: Boolean,
    active: Boolean
  },
  data: function data() {
    return {
      isFirst: "首次优惠",
      isRechargeCard: "充值卡",
      isBatch: false
    };
  },
  computed: {
    // 用户是否是新用户
    isFirstRechargeUser: function isFirstRechargeUser() {
      return this.$store.state.isFirstRechargeUser;
    }
  },
  methods: {
    // 展示什么提示
    showTips: function showTips() {
      return this.isFirst;

      // 1 是否属于卡密 (充值卡)
      if (this.productInfo.pin && !isBatch) {
        return this.isRechargeCard;
      } else if (productInfo.firstDiscount && !this.productInfo.pin && !isBatch) {
        // 2 是否首次优惠
        return this.isFirst;
      } else {
        return this.productInfo.hotTips;
      }
    }
  },
  created: function created() {
    this.isBatch = RegExp(/batch\/recharge/).test(this.$route.path);
  }
};