var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "miniprogram"
  }, [_c("p", {
    staticClass: "miniprogram-subtitle"
  }, [_vm._v("游全球 "), _c("span", [_vm._v("会员小程序")])]), _c("h1", {
    staticClass: "miniprogram-title"
  }, [_vm._v("积分抵现 专属折扣")]), _c("div", {
    staticClass: "miniprogram-main"
  }, [_c("em", {
    staticClass: "miniprogram-main-title"
  }, [_vm._v("扫码成功，即可成为会员")]), _c("div", {
    staticClass: "miniprogram-main-code"
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: require("@@/userCenter/miniprogramCode.jpg"),
      alt: "miniprogramCode"
    }
  })]), _c("p", {
    staticClass: "miniprogram-main-text"
  }, [_vm._v("长按扫码访问")]), _c("p", {
    staticClass: "miniprogram-main-tips"
  }, [_vm._v("*若无法长按扫码，请截屏保存图片后微信识别二维码")])]), _c("div", {
    staticClass: "miniprogram-bottom"
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: require("@@/userCenter/logo.png"),
      alt: "游全球"
    }
  })])]);
}];
render._withStripped = true;
export { render, staticRenderFns };