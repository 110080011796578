var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("van-radio-group", {
    attrs: {
      "checked-color": "#FC6E50",
      "icon-size": "26px"
    },
    model: {
      value: _vm._payTypeRadio,
      callback: function callback($$v) {
        _vm._payTypeRadio = $$v;
      },
      expression: "_payTypeRadio"
    }
  }, [_c("p", {
    staticClass: "payType-title"
  }, [_vm._v("请选择付款方式")]), _c("ul", {
    staticClass: "payType"
  }, [!_vm.isAliPayLife ? _c("li", {
    staticClass: "payType-item",
    on: {
      click: function click($event) {
        return _vm.$emit("input", "wc_pay");
      }
    }
  }, [_c("div", [_c("zch-icon", {
    attrs: {
      icon: "wechatPay"
    }
  }), _c("p", [_vm._v("微信支付")])], 1), _c("van-radio", {
    attrs: {
      name: "wc_pay"
    }
  })], 1) : _vm._e(), _c("li", {
    staticClass: "payType-item",
    on: {
      click: function click($event) {
        return _vm.$emit("input", "ali_pay");
      }
    }
  }, [_c("div", [_c("zch-icon", {
    attrs: {
      icon: "aliPay"
    }
  }), _c("p", [_vm._v("支付宝支付")])], 1), _c("van-radio", {
    attrs: {
      name: "ali_pay"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };