var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "productItem",
    on: {
      click: function click($event) {
        return _vm.$emit("click", $event);
      }
    }
  }, [_c("em", [_vm._v(" 更多选择 "), _c("van-icon", {
    attrs: {
      name: "arrow-down"
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };