import wx from 'weixin-js-sdk';
export default {
  data: function data() {
    return {
      templateIdArr: [] // 假设这是您需要的模板ID数组
    };
  },
  mounted: function mounted() {
    this.initializeWX();
  },
  methods: {
    initializeWX: function initializeWX() {
      var _this = this;
      UserService.getjssign({
        url: location.href
      }).then(function (res) {
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: [],
          openTagList: ["wx-open-subscribe"]
        });
        wx.ready(function () {
          var btn = document.getElementById("subscribe-btn");
          btn.addEventListener("success", function (e) {
            console.log("success", e.detail);
          });
          btn.addEventListener("error", function (e) {
            console.log("fail", e.detail);
            _this.routerBtn();
          });
        });
      });
    },
    subscribe: function subscribe() {
      console.log('模拟订阅操作');
    },
    routerBtn: function routerBtn() {
      console.log('处理路由跳转');
    }
  }
};