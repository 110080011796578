var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "networkError"
    }
  }, [_c("svg", {
    staticClass: "icon"
  }, [_c("use", {
    attrs: {
      "xlink:href": "#icon-tanhao"
    }
  })]), _c("h2", [_vm._v("网络异常，页面加载失败，请重新尝试或联系客服协助处理！")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };