var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "all"
  }, [_c("div", {
    staticClass: "coupon",
    attrs: {
      id: "userCoupon"
    }
  }, [_c("div", {
    staticClass: "first-nav-button"
  }, [_c("div", {
    staticClass: "notuse-nav",
    class: {
      select: _vm.active == 0
    },
    on: {
      click: function click($event) {
        return _vm.changeTable(0);
      }
    }
  }, [_c("span", [_vm._v("未使用")])]), _c("div", {
    staticClass: "lose-nav",
    class: {
      select: _vm.active == 1
    },
    on: {
      click: function click($event) {
        return _vm.changeTable(1);
      }
    }
  }, [_c("span", [_vm._v("已失效")])])]), _vm.active == 0 ? _c("div", {
    staticClass: "not-use",
    staticStyle: {
      height: "100%",
      overflow: "auto"
    }
  }, [_c("div", {
    staticClass: "nav"
  }, [_c("div", {
    staticClass: "all",
    class: {
      selected: _vm.activeName == 1
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(1);
      }
    }
  }, [_c("p", [_vm._v("全部")])]), _c("div", {
    staticClass: "be-common",
    class: {
      selected: _vm.activeName == 2
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(2);
      }
    }
  }, [_c("p", [_vm._v("通用券")])]), _c("div", {
    staticClass: "hf",
    class: {
      selected: _vm.activeName == 3
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(3);
      }
    }
  }, [_c("p", [_vm._v("话费券")])]), _c("div", {
    staticClass: "ll",
    class: {
      selected: _vm.activeName == 4
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(4);
      }
    }
  }, [_c("p", [_vm._v("流量券")])]), _c("div", {
    staticClass: "ll",
    class: {
      selected: _vm.activeName == 5
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(5);
      }
    }
  }, [_c("p", [_vm._v("套餐券")])])])]) : _vm._e(), _c("div", {
    staticClass: "list"
  }, [_vm.active == 0 ? _c("van-list", {
    attrs: {
      finished: _vm.finished1
    },
    on: {
      load: _vm.onLoad1
    },
    model: {
      value: _vm.loading1,
      callback: function callback($$v) {
        _vm.loading1 = $$v;
      },
      expression: "loading1"
    }
  }, [_c("coupon-list", {
    attrs: {
      active: _vm.active,
      "coupon-list": _vm.showNotUseFilterList
    }
  })], 1) : _vm._e()], 1), _vm.active == 1 ? _c("div", {
    staticClass: "not-use grayColor",
    staticStyle: {
      height: "100%",
      overflow: "auto"
    }
  }, [_c("div", {
    staticClass: "nav"
  }, [_c("div", {
    staticClass: "all",
    class: {
      selected: _vm.activeName == 6
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(6);
      }
    }
  }, [_c("p", [_vm._v("全部")])]), _c("div", {
    staticClass: "be-common",
    class: {
      selected: _vm.activeName == 7
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(7);
      }
    }
  }, [_c("p", [_vm._v("已使用")])]), _c("div", {
    staticClass: "hf",
    class: {
      selected: _vm.activeName == 8
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(8);
      }
    }
  }, [_c("p", [_vm._v("已过期")])]), _c("div", {
    staticClass: "hf",
    class: {
      selected: _vm.activeName == 9
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(9);
      }
    }
  }, [_c("p", [_vm._v("使用中")])])])]) : _vm._e(), _vm.active == 1 ? _c("van-list", {
    attrs: {
      finished: _vm.finished2
    },
    on: {
      load: _vm.onLoad2
    },
    model: {
      value: _vm.loading2,
      callback: function callback($$v) {
        _vm.loading2 = $$v;
      },
      expression: "loading2"
    }
  }, [_c("coupon-list", {
    attrs: {
      active: _vm.active,
      "coupon-list": _vm.showLapseFilterList
    }
  })], 1) : _vm._e(), _c("router-link", {
    attrs: {
      tag: "li",
      to: "/user/coupon/coupon-record"
    }
  }, [_c("div", {
    staticClass: "bottom-Records"
  }, [_vm._v("优惠劵记录")])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };