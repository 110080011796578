import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { isMobileBrowser, isPC, isAliPayLife, isWechatBrowser } from '@/utils/index';
export default {
  name: 'Page404',
  components: _defineProperty({}, _Button.name, _Button),
  data: function data() {
    return {
      isMobile: isMobileBrowser,
      ispc: isPC(),
      isAli: isAliPayLife,
      isWechat: isWechatBrowser
    };
  },
  created: function created() {
    if (!this.isAli && !this.isWechat) {
      // alert(1)
      this.$router.push('/');
    }
    // alert(1)
  }
};