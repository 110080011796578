import requestWithLoading from '@/utils/requestWithLoading';
import axios from 'axios';
export function getCouponList() {
  return requestWithLoading({
    url: '/wx/coupon/getUserCoupon',
    method: 'get'
  });
}
export function getCouponPageList(data) {
  return requestWithLoading({
    url: '/wx/coupon/getUserCouponPage',
    method: 'post',
    data: data
  });
}