import requestWithLoading from '@/utils/requestWithLoading';
export function getUserInfo() {
  return requestWithLoading({
    url: '/wx/user/info',
    method: 'get'
  });
}
export function createMember(data) {
  return requestWithLoading({
    url: '/wx/member/create',
    method: 'POST',
    data: data
  });
}