var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "pointRecord"
    }
  }, [_c("van-tabs", {
    attrs: {
      swipeable: "",
      sticky: "",
      animated: "",
      color: "#5495E5",
      "title-active-color": "#5495E5",
      "title-inactive-color": "#848484",
      "line-width": "3em"
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c("van-tab", {
    attrs: {
      "title-style": "font-size:15px;",
      name: "IN",
      title: "积分收入"
    }
  }, [_c("record-list", {
    attrs: {
      operation: "IN"
    }
  })], 1), _c("van-tab", {
    attrs: {
      "title-style": "font-size:15px;",
      name: "OUT",
      title: "积分支出"
    }
  }, [_c("record-list", {
    attrs: {
      operation: "OUT"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };