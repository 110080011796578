var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "zchCountryEmpty"
  }, [_c("div", [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.KF_QR_CODE,
      alt: "二维码"
    }
  })]), _c("p", [_vm._v("抱歉！该国家充值服务暂时维护中~")]), _c("p", [_vm._v("如需充值，添加客服微信")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };