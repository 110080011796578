import "core-js/modules/es.json.stringify.js";
export default {
  mounted: function mounted() {
    var mchData = {
      action: 'onIframeReady',
      displayStyle: 'SHOW_CUSTOM_PAGE',
      height: '600px'
    };
    var postData = JSON.stringify(mchData);
    parent.postMessage(postData, '*');
  },
  methods: {
    toBusiness: function toBusiness() {
      console.log(location.href);
      var pos, str, para, parastr;
      var array = [];
      str = location.href;
      parastr = str.split("?")[1];
      var out_trade_no = parastr.split("&")[0].split("=")[1];
      var jumpOutUrl = process.env.NODE_ENV === "production" ? 'https://wechat.globalcharge.cn/home/order/payment' : 'https://test-wechat-global.kqlink.com/home/order/payment';
      var mchData = {
        action: 'jumpOut',
        jumpOutUrl: jumpOutUrl
      };
      var postData = JSON.stringify(mchData);
      parent.postMessage(postData, '*');
    }
  }
};