import requestWithLoading from '@/utils/requestWithLoading';
// 充值
export function orderPay(data) {
  return requestWithLoading({
    url: '/wx/charge/order',
    method: 'post',
    data: data
  });
}
// 批量充值
export function getPreOrderBatch(data) {
  return requestWithLoading({
    url: '/wx/charge/batch/order',
    method: 'post',
    data: data
  });
}