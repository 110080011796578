import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "core-js/modules/es.function.name.js";
import ZchIcon from '@/components/ZchIcon';
import { isAliPayLife, isWechatBrowser } from "@/utils/index.js";
export default {
  name: 'ZchPayType',
  components: _defineProperty(_defineProperty({
    ZchIcon: ZchIcon
  }, _RadioGroup.name, _RadioGroup), _Radio.name, _Radio),
  data: function data() {
    return {
      isAliPayLife: isAliPayLife,
      isWechatBrowser: isWechatBrowser
    };
  },
  props: {
    value: String
  },
  computed: {
    _payTypeRadio: {
      get: function get() {
        return this.value;
      },
      set: function set(radio) {
        this.$emit('input', radio);
      }
    }
  }
};