var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "posterWrapper"
  }, [_c("img", {
    attrs: {
      src: _vm.posterImg,
      width: "100%"
    }
  })]), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "saveButton"
  }, [_c("span", [_vm._v("让好友当面扫码或长按发送给朋友")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };