import request from '@/utils/request';
export function initLotteryPageData() {
  return request({
    url: 'wx/activity/getLotteryPrize',
    method: 'get'
  });
}
export function getLotteryPrize() {
  return request({
    url: 'wx/activity/lottery',
    method: 'post'
  });
}
export function getLotteryPrizeRecord(data) {
  return request({
    url: 'wx/activity/getWinningRecord',
    method: 'post',
    data: data
  });
}