import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import ZchIcon from '@/components/ZchIcon';
import ZchCountryEmpty from '@/components/ZchCountryEmpty';
export default {
  name: 'ZchCountryList',
  components: _defineProperty(_defineProperty(_defineProperty(_defineProperty({
    ZchIcon: ZchIcon,
    ZchCountryEmpty: ZchCountryEmpty
  }, _Popup.name, _Popup), _Field.name, _Field), _Image.name, _Image), _Dialog.Component.name, _Dialog.Component),
  props: {
    countrySelectorVisible: Boolean,
    countryList: Array,
    selectedCountry: Object
  },
  computed: {
    _countrySelectorVisible: {
      set: function set(value) {
        this.$emit('close', value);
      },
      get: function get() {
        return this.countrySelectorVisible;
      }
    },
    filterCountryList: function filterCountryList() {
      var _this = this;
      // console.log(this.)
      var list = this.countryList;
      list.map(function (item) {
        item.anList = item.an.split(',');
      });
      // return this.countryList.filter(v => v.searchKeyword.indexOf(this.searchCountryKeyword) >= 0)
      return list.filter(function (v) {
        return v.searchKeyword.indexOf(_this.searchCountryKeyword) >= 0;
      });
    }
  },
  data: function data() {
    return {
      searchCountryKeyword: '',
      countryInvalidVisible: false
    };
  },
  methods: {
    selected: function selected(item) {
      console.log('zchCountryList 中的 country', item);
      this.searchCountryKeyword = '';
      this.$emit('selected', item);
    },
    getRealImg: function getRealImg(item) {
      return "".concat(process.env.VUE_APP_IMG_URL, "countryFlags/").concat(item.ename, ".svg");
    }
  }
};