var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "shareRecords"
  }, [_c("header", [_c("div", {
    staticClass: "friend"
  }, [_c("em", [_vm._v(_vm._s(_vm.shareRecordList.length) + " "), _c("span", [_vm._v("人")])]), _c("p", [_vm._v("已邀好友")])]), _c("div", {
    staticClass: "point"
  }, [_c("em", [_vm._v(_vm._s(_vm.totalPoints))]), _c("p", [_vm._v("已获积分")])])]), _vm._m(0), _vm.shareRecordList.length === 0 ? _c("div", {
    staticClass: "listItem-empty"
  }, [_vm._v("点击下方按钮开始邀请把~")]) : _vm._e(), _vm._l(_vm.shareRecordList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "listItem"
    }, [_c("p", [_vm._v(_vm._s(_vm.displayNickname(item)))]), _c("p", [_vm._v(_vm._s(item.givePoint))])]);
  }), _c("footer", {
    on: {
      click: _vm.goToSharePoster
    }
  }, [_vm._v("继续邀请")])], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "listTitle"
  }, [_c("p", [_vm._v("我的好友")]), _c("p", [_vm._v("获取积分")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };