import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "popup-coupon HF",
    class: _vm.couponInfo.type
  }, [_vm.couponInfo.couponType === "full" ? _c("p", {
    staticClass: "popup-coupon-price"
  }, [_c("em", [_vm._v(_vm._s(_vm.couponInfo.value))]), _vm._v("元")]) : _vm._e(), _vm.couponInfo.couponType === "discount" ? _c("p", {
    staticClass: "popup-coupon-price"
  }, [_c("em", [_vm._v(_vm._s(_vm.couponInfo.discount))]), _vm._v("折")]) : _vm._e(), _c("div", {
    staticClass: "popup-coupon-info"
  }, [_c("cite", [_vm._v(_vm._s(_vm.couponInfo.name))]), _c("p", [_vm._v("有效期至" + _vm._s(_vm.couponInfo.expireTime && _vm.couponInfo.expireTime.split(" ")[0]))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };