var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "prizeRecord"
  }, [_c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "这是我的底线"
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c("ul", {
    staticClass: "prizeRecord-list"
  }, _vm._l(_vm.lotteryPrizeRecord, function (item) {
    return _c("li", {
      key: item.id,
      staticClass: "prizeRecord-list-item"
    }, [_c("em", [_vm._v(_vm._s(item.message))]), _c("p", [_vm._v(_vm._s(item.created))])]);
  }), 0)]), _c("div", {
    staticClass: "bottomButtonBar"
  }, [_c("router-link", {
    attrs: {
      to: "/home"
    }
  }, [_vm._v("立即使用优惠券")]), _c("router-link", {
    attrs: {
      to: "/user/coupon"
    }
  }, [_vm._v("查看我的优惠券")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };