import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import requestWithLoading from '@/utils/requestWithLoading';

// 获取充值记录
export function getRechargeRecord(data) {
  return requestWithLoading({
    url: '/wx/order/user',
    method: 'post',
    data: data
  });
}
export function orderPayAgain(data) {
  return requestWithLoading({
    url: '/wx/charge/again/pay',
    method: 'post',
    data: data
  });
}
export function cancelOrder(data) {
  return requestWithLoading({
    url: '/wx/charge/cancelOrder',
    method: 'post',
    data: data
  });
}

/**
 * 根据运营商id获取信息 pinQueryUrl
 */
export function getCarrierById(id) {
  return requestWithLoading({
    url: "/wx/carrier/get/".concat(id),
    method: 'get'
  });
}

//获取单个国家的信息
// export function getCountryInfoByCC(cc) {
//   return requestWithLoading({
//     url:`/wx/country/getCountry/${cc}`,
//     method:"get"
//   })
// }
export function getCountryInfoByCC(CC) {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(process.env.VUE_APP_OSSURL, "/singleCountryInfo/").concat(CC, ".json"),
      method: 'get'
    }).then(function (res) {
      res.data = JSON.parse(decodeURIComponent(res.data));
      console.log(res);
      resolve(res);
    }).catch(function (err) {
      reject(err);
    });
  });
}
export function getUserInfo() {
  return requestWithLoading({
    url: '/wx/user/info',
    method: 'get'
  });
}
export function getCarrierInfo(carrierId, type) {
  return requestWithLoading({
    url: "/wx/carrier/getCarrierInfo?carrierId=".concat(carrierId, "&type=").concat(type),
    method: 'get'
  });
}