import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("van-popup", {
    attrs: {
      "get-container": "#app",
      "close-on-popstate": true,
      closeable: "",
      "safe-area-inset-bottom": "",
      position: "bottom"
    },
    model: {
      value: _vm._orderVisible,
      callback: function callback($$v) {
        _vm._orderVisible = $$v;
      },
      expression: "_orderVisible"
    }
  }, [_c("div", {
    staticClass: "orderWrapper"
  }, [_c("h4", [_vm._v("确认付款")]), _c("van-radio-group", {
    attrs: {
      "checked-color": "#FC6E50",
      "icon-size": "26px"
    },
    model: {
      value: _vm.discountRadio,
      callback: function callback($$v) {
        _vm.discountRadio = $$v;
      },
      expression: "discountRadio"
    }
  }, [_c("ul", {
    staticClass: "orderDetail"
  }, [_c("li", {
    staticClass: "orderDetail-item"
  }, [_c("label", [_vm._v("订单详情")]), _c("p", [_vm._v(_vm._s(_vm.displayOrderName))])]), _vm.isMyTaxValueArrItem() && _vm.orderDetail.productType === "HF" ? _c("li", {
    staticClass: "orderDetail-item"
  }, [_c("label", [_vm._v("预计到账")]), _c("p", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("toFixed2")(_vm.orderDetail.baseValue * (1 - _vm.currentTipsInfo.taxValue / 100))) + " " + _vm._s(_vm.orderDetail.unit) + " "), _c("span", {
    staticStyle: {
      color: "#808080"
    }
  }, [_vm._v("(政府收取" + _vm._s(_vm.currentTipsInfo.taxValue) + "%税款)")])])]) : _vm._e(), _vm.orderDetail.phoneList ? [_c("li", {
    staticClass: "orderDetail-item"
  }, [_c("label", [_vm._v("手机号码数量")]), _c("p", [_vm._v(_vm._s(_vm.orderDetail.phoneList.length))])])] : [_c("li", {
    staticClass: "orderDetail-item"
  }, [_c("label", [_vm._v("手机号码")]), _c("p", [_vm._v("+" + _vm._s(_vm.orderDetail.areaNumber) + " " + _vm._s(_vm.orderDetail.account))])])], _vm.deepCopyUsedPhoneList.length == 0 ? _c("li", {
    staticClass: "orderDetail-item"
  }, [_c("label", [_vm._v("提示")]), _c("p", [_vm._v(" 该号码 "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v('"可能首次充值"，')]), _vm._v("请留意是否正确，避免充错 ")])]) : _vm._e(), _c("li", {
    staticClass: "orderDetail-item"
  }, [_c("label", [_vm._v("商品价格")]), _vm.orderDetail.phoneList ? [_c("p", [_vm._v(" " + _vm._s(_vm.orderDetail.name + " x " + _vm.orderDetail.phoneList.length + " = " + _vm.orderDetail.payAmount) + "元 ")])] : [_c("p", [_vm._v(_vm._s(_vm._f("toFixed2")(_vm.orderDetail.price)) + "元")])]], 2), !_vm.orderDetail.phoneList && !_vm.isWindowBrowser && !_vm.orderDetail.firstDiscount && this.isFirstRechargeUser || !this.isFirstRechargeUser ? [_vm.discountVisible ? _c("li", {
    staticClass: "orderDetail-item radio",
    class: {
      selected: _vm.discountRadio === "point" && !_vm.orderDetail.firstDiscount,
      disabled: _vm.displayPoint === 0
    },
    on: {
      click: _vm.choosePointRadio
    }
  }, [_c("label", [_vm._v("积分"), _vm.displayPoint ? _c("span", [_vm._v("可用"), _c("em", [_vm._v(_vm._s(_vm._f("toFixed0")(_vm.displayPoint)) + "积分")]), _vm._v("抵扣"), _c("b", [_vm._v("￥" + _vm._s(_vm._f("toFixed2")(_vm.displayPoint / 100)) + "元")])]) : _vm._e()]), _c("van-radio", {
    attrs: {
      name: "point",
      disabled: _vm.displayPoint === 0
    }
  })], 1) : _vm._e(), _vm.discountVisible ? _c("li", {
    staticClass: "orderDetail-item radio",
    class: {
      selected: _vm.discountRadio === "coupon" && !_vm.orderDetail.firstDiscount,
      disabled: _vm.couponList.length === 0
    },
    on: {
      click: _vm.chooseCouponRadio
    }
  }, [_c("label", [_vm._v("优惠券"), _c("em", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.couponDetail.name,
      expression: "couponDetail.name"
    }]
  }, [_vm._v(_vm._s(_vm.couponDetail.name))])]), _c("van-radio", {
    attrs: {
      name: "coupon",
      disabled: _vm.couponList.length === 0
    }
  })], 1) : _vm._e(), _vm.discountVisible ? _c("li", {
    staticClass: "orderDetail-item radio",
    class: {
      selected: _vm.discountRadio === null
    },
    on: {
      click: function click($event) {
        _vm.discountRadio = null;
      }
    }
  }, [_c("label", [_vm._v("不使用优惠")]), _c("van-radio", {
    attrs: {
      name: null
    }
  })], 1) : _vm._e()] : _vm._e()], 2)]), !_vm.orderDetail.phoneList && !_vm.orderDetail.firstDiscount ? [_vm.discountVisible ? _c("span", {
    staticClass: "orderDiscountTips"
  }, [_vm._v("*积分优惠券二选一")]) : _vm._e()] : _vm._e(), _c("zch-pay-type", {
    model: {
      value: _vm.payType,
      callback: function callback($$v) {
        _vm.payType = $$v;
      },
      expression: "payType"
    }
  }), _vm.orderDetail.payAmount ? [_c("div", {
    staticClass: "orderButton"
  }, [_c("p", [_vm._v(" 应付金额"), _c("span", [_vm._v("￥")]), _c("b", [_vm._v(_vm._s(_vm._f("toFixed2")(_vm.orderDetail.payAmount)))])]), _c("em", {
    on: {
      click: _vm.buyNow
    }
  }, [_vm._v("立即付款")])])] : [_c("div", {
    staticClass: "orderButton"
  }, [_c("p", [_vm._v(" 应付金额"), _c("span", [_vm._v("￥")]), _c("b", [_vm._v(_vm._s(_vm._f("toFixed2")(_vm.payAmount)))])]), _vm.discountVisible && _vm.discountPrice !== 0 ? _c("span", [_vm._v("已优惠￥" + _vm._s(_vm._f("toFixed2")(_vm.discountPrice)))]) : _vm._e(), _c("em", {
    on: {
      click: _vm.buyNow
    }
  }, [_vm._v("立即付款")])])], _c("van-popup", {
    style: {
      height: "100%"
    },
    attrs: {
      "safe-area-inset-bottom": "",
      closeable: "",
      position: "bottom",
      "get-container": "#app"
    },
    on: {
      "click-close-icon": _vm.closeIcon
    },
    model: {
      value: _vm.couponListVisible,
      callback: function callback($$v) {
        _vm.couponListVisible = $$v;
      },
      expression: "couponListVisible"
    }
  }, [_c("zch-coupon", {
    attrs: {
      "coupon-list": _vm.couponList,
      "coupon-detail": _vm.couponDetail
    },
    on: {
      useCoupon: function useCoupon($event) {
        return _vm.useCoupon($event);
      }
    }
  })], 1), _c("zch-code-pay", {
    attrs: {
      "code-pay-visible": _vm.codePayVisible,
      "qr-code-url": _vm.qrCodeUrl
    },
    on: {
      close: function close($event) {
        _vm.codePayVisible = $event;
      }
    }
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };