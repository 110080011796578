var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "containers"
  }, [_c("div", {
    attrs: {
      id: "subscribe-btn"
    },
    on: {
      click: _vm.subscribe
    }
  }, [_c("div", {
    staticClass: "btn",
    staticStyle: {
      "text-align": "center",
      color: "#664502",
      "line-height": "45px",
      "font-size": "16px"
    }
  }, [_vm._v(" 点击进入 ")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };