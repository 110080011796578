import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
Vue.use(_Icon);
export default {
  name: "CouponItem",
  props: {
    couponInfo: Object,
    user: Boolean
  },
  data: function data() {
    return {
      showDetail: false,
      nowCountryName: null
    };
  },
  created: function created() {
    // 加载国家信息
    this.loadFromSessionStorage();
  },
  methods: {
    loadFromSessionStorage: function loadFromSessionStorage() {
      var _this = this;
      var value = sessionStorage.getItem("flags");
      if (value) {
        var AllFLAGS = JSON.parse(value);
        AllFLAGS.find(function (item) {
          if (item.cc == _this.couponInfo.countryCode) {
            _this.nowCountryName = item.cn;
          }
        });
      }
    },
    changeShowDetail: function changeShowDetail() {
      console.log("couponInfo.productSet", this.couponInfo.productSet);
      this.showDetail = !this.showDetail;
    },
    useCoupon: function useCoupon() {
      console.log("couponInfo.status==>", this.couponInfo.status);
      if (this.couponInfo.status == "NOT_USE") {
        this.user ? this.$router.push({
          path: "/"
        }) : this.$emit("click");
      }
    },
    conponType: function conponType(type) {
      if (type === "LL") {
        return "流量券";
      } else if (type === "HF") {
        return "话费券";
      } else if (type === "ALL") {
        return "通用券";
      }
    }
  }
};