import "core-js/modules/es.array.concat.js";
import requestWithLoading from '@/utils/requestWithLoading';
export function getUserInfo() {
  return requestWithLoading({
    url: '/wx/user/info',
    method: 'get'
  });
}
export function createMember(data) {
  return requestWithLoading({
    url: '/wx/member/create',
    method: 'POST',
    data: data
  });
}

//单笔充值详情
export function getOrderDetail(outTradeNo) {
  return requestWithLoading({
    url: "/wx/order/unifiedDetail/".concat(outTradeNo),
    method: 'get'
  });
}
//获取交易凭证
export function getOrderCertificate(outTradeNo, language) {
  return requestWithLoading({
    url: "/wx/order/receipt/".concat(outTradeNo),
    method: 'get',
    params: {
      language: language
    }
  });
}

//用户添加投诉
export function addComplaint(data) {
  return requestWithLoading({
    url: '/wx/userComplain/add',
    method: 'POST',
    data: data
  });
}

//用户关闭投诉
export function closeComplaint(productOrderId, resolved) {
  return requestWithLoading({
    url: "/wx/userComplain/close/".concat(productOrderId, "?resolved=").concat(resolved),
    method: 'POST'
  });
}
//获取投诉信息
export function getComplaintInfo(productOrderId) {
  return requestWithLoading({
    url: "/wx/userComplain/get/".concat(productOrderId),
    method: 'GET'
  });
}