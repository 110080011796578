import { getFullDate } from '../../utils/index';
export default {
  name: "PaySuccess",
  data: function data() {
    return {
      successInfo: {},
      errorInfo: {},
      orderId: '12412121515154',
      time: getFullDate(),
      pinID: "123156161545",
      imgUrl: 'https://globalcharge.oss-cn-hongkong.aliyuncs.com/prod/wechat/image/paySuccess.png'
    };
  },
  created: function created() {},
  methods: {
    submit: function submit() {
      console.log("支付成功！");
    }
  }
};