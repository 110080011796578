import requestWithLoading from '@/utils/requestWithLoading';

//单笔充值详情
export function getOrderDetail(outTradeNo) {
  return requestWithLoading({
    url: "/wx/order/unifiedDetail/".concat(outTradeNo),
    method: 'get'
  });
}
//获取交易凭证
export function getOrderCertificate(outTradeNo, language) {
  return requestWithLoading({
    url: "/wx/order/receipt/".concat(outTradeNo),
    method: 'get',
    params: {
      language: language
    }
  });
}