var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "couponList"
    }
  }, [_c("ul", {
    staticClass: "couponList"
  }, [_vm.couponList.length === 0 ? _c("p", {
    staticClass: "couponList-empty"
  }, [_vm._v("当前暂无该类型优惠券")]) : _vm._e(), _vm._l(_vm.couponList, function (item) {
    return _c("coupon-item", {
      key: item.uuid,
      attrs: {
        "coupon-info": item,
        user: ""
      }
    });
  }), _vm.couponList.length !== 0 ? _c("p", {
    staticClass: "couponList-end"
  }, [_vm._v("这是我的底线")]) : _vm._e()], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };