export default {
  name: 'SharePoster',
  data: function data() {
    return {
      posterImg: ''
    };
  },
  created: function created() {
    this.posterImg = process.env.VUE_APP_QRIMG_URL + this.$route.query.userkey + '.png';
  }
};