import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      position: "relative"
    }
  }, [_vm.productList.length === 0 && _vm.carrier === "" && _vm.flag == true || _vm.displayPhoneNumber === "" || _vm.isChangeCountry === true && _vm.productList.length === 0 && !_vm.productEmptyVisible ? _c("div", {
    staticClass: "my_tips",
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
      "margin-top": "110px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "60px",
      "margin-bottom": "35px"
    },
    attrs: {
      src: require("@/assets/home/tips1.png"),
      alt: "tips"
    }
  }), _vm._v(" " + _vm._s(_vm.emptyTips) + " ")]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowNew && _vm.productEmptyVisible && _vm.TcProductList.length === 0 && _vm.displayPhoneNumber.length > 0 || !_vm.isShowNew && _vm.name === "QUERY",
      expression: "\n     !isShowNew && (productEmptyVisible &&\n        TcProductList.length === 0 &&\n        displayPhoneNumber.length > 0) ||\n         !isShowNew && name === 'QUERY'  \n    "
    }],
    staticClass: "rechargeBar-content-empty",
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c("div", [_c("van-image", {
    attrs: {
      width: "100%",
      src: _vm.KF_QR_CODE,
      alt: "二维码"
    }
  })], 1), _vm.name === "QUERY" ? _c("p", [_vm._v("请联系客服查询")]) : _c("p", [_vm._v("产品维护中，如需充值请联系客服")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === false && _vm.isShowNew && _vm.name == "LL" && _vm.displayCarrierName != "请选择运营商",
      expression: "flag === false && isShowNew && name == 'LL' && displayCarrierName != '请选择运营商' "
    }]
  }, [_c("ImageTextAndArticleDetail", {
    attrs: {
      llStudyNewsId: _vm.llStudyNewsId,
      llStudyNewsList: _vm.llStudyNewsList,
      displayCountryCode: _vm.displayCountryCode,
      name: _vm.name
    }
  })], 1), _c("ul", {
    staticClass: "rechargeBar-content"
  }, [[_vm.name == "LL" ? [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === false && _vm.productList.length > 0,
      expression: "flag === false && productList.length > 0"
    }],
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒: 流量充值前,请确保有话费余额。 ")], 1), _vm.mouthBag.length > 0 ? [_c("div", {
    staticClass: "line_class"
  }, [_vm._v("月包")]), _vm._l(_vm.mouthBag, function (item, index) {
    return index < _vm.mouthIndex || _vm.mouthBag.length === 4 ? _c("product-item-l-mouth", {
      key: item.code,
      attrs: {
        disabled: _vm.disabledProduct,
        type: _vm.name,
        active: index + _vm.weekBag.length + _vm.todayBag.length === _vm.activeIndex,
        "product-info": item
      },
      on: {
        click: function click($event) {
          return _vm.handleClickProduct(item, index + _vm.weekBag.length + _vm.todayBag.length);
        }
      }
    }) : _vm._e();
  }), _vm.showMoreMouth == false && _vm.mouthBag.length > 3 && _vm.mouthBag.length != 4 ? _c("checkMore", {
    on: {
      click: function click($event) {
        return _vm.showMore("showMoreMouth");
      }
    }
  }) : _vm._e(), _vm.proxyUser && _vm.mouthBag.length > 0 || _vm.ispc && _vm.mouthBag.length > 0 ? _c("div", {
    staticClass: "productItem",
    on: {
      click: _vm.TODDBatch
    }
  }, [_vm._m(0)]) : _vm._e()] : _vm._e(), _vm.weekBag.length > 0 ? [_c("div", {
    staticClass: "line_class"
  }, [_vm._v("周包")]), _vm._l(_vm.weekBag, function (item, index) {
    return index < _vm.weekIndex || _vm.weekBag.length === 4 ? _c("product-item-l-week", {
      key: item.code,
      attrs: {
        disabled: _vm.disabledProduct,
        type: _vm.name,
        active: index + _vm.todayBag.length === _vm.activeIndex,
        "product-info": item
      },
      on: {
        click: function click($event) {
          return _vm.handleClickProduct(item, index + _vm.todayBag.length);
        }
      }
    }) : _vm._e();
  }), _vm.showMoreWeek == false && _vm.weekBag.length > 3 && _vm.weekBag.length != 4 ? _c("checkMore", {
    on: {
      click: function click($event) {
        return _vm.showMore("showMoreWeek");
      }
    }
  }) : _vm._e(), _vm.proxyUser && _vm.weekBag.length > 0 || _vm.ispc && _vm.weekBag.length > 0 ? _c("div", {
    staticClass: "productItem",
    on: {
      click: _vm.TODDBatch
    }
  }, [_vm._m(1)]) : _vm._e()] : _vm._e(), _vm.todayBag.length > 0 ? [_c("div", {
    staticClass: "line_class"
  }, [_vm._v("日包")]), _vm._l(_vm.todayBag, function (item, index) {
    return index < _vm.todayIndex || _vm.todayBag.length === 4 ? _c("product-item-l", {
      key: item.code,
      attrs: {
        disabled: _vm.disabledProduct,
        type: _vm.name,
        active: index === _vm.activeIndex,
        "product-info": item
      },
      on: {
        click: function click($event) {
          return _vm.handleClickProduct(item, index);
        }
      }
    }) : _vm._e();
  }), _vm.showMoreToday == false && _vm.todayBag.length > 3 && _vm.todayBag.length != 4 ? _c("checkMore", {
    on: {
      click: function click($event) {
        return _vm.showMore("showMoreToday");
      }
    }
  }) : _vm._e(), _vm.proxyUser && _vm.todayBag.length > 0 || _vm.ispc && _vm.todayBag.length > 0 ? _c("div", {
    staticClass: "productItem",
    on: {
      click: _vm.TODDBatch
    }
  }, [_vm._m(2)]) : _vm._e()] : _vm._e(), _vm.otherBag.length > 0 ? [_c("div", {
    staticClass: "line_class"
  }, [_vm._v("其他")]), _vm._l(_vm.otherBag, function (item, index) {
    return index < _vm.otherIndex || _vm.otherBag.length === 4 ? _c("product-item-l-other", {
      key: item.code,
      attrs: {
        disabled: _vm.disabledProduct,
        type: _vm.name,
        active: index + _vm.mouthBag.length + _vm.weekBag.length + _vm.todayBag.length === _vm.activeIndex,
        "product-info": item
      },
      on: {
        click: function click($event) {
          return _vm.handleClickProduct(item, index + _vm.mouthBag.length + _vm.weekBag.length + _vm.todayBag.length);
        }
      }
    }) : _vm._e();
  }), _vm.showMoreOther == false && _vm.otherBag.length > 3 && _vm.otherBag.length != 4 ? _c("checkMore", {
    on: {
      click: function click($event) {
        return _vm.showMore("showMoreOther");
      }
    }
  }) : _vm._e(), _vm.proxyUser && _vm.otherBag.length > 0 || _vm.ispc && _vm.otherBag.length > 0 ? _c("div", {
    staticClass: "productItem",
    on: {
      click: _vm.TODDBatch
    }
  }, [_vm._m(3)]) : _vm._e()] : _vm._e()] : _vm.name == "HF" ? [_vm.flag === false && _vm.productList.length > 0 && _vm.currentTipsInfo == "" ? _c("div", {
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒: 请确认SIM卡在手机内并正常使用中 ")], 1) : _vm._e(), _vm.flag === false && _vm.productList.length > 0 && _vm.currentTipsInfo != "" ? _c("div", {
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒: 每笔话费充值，" + _vm._s(_vm.displayCname) + "政府均收取" + _vm._s(_vm.currentTipsInfo.taxValue) + "%税款 ")], 1) : _vm._e(), _vm._l(_vm.productList, function (item, index) {
    return _c("product-item", {
      key: item.code,
      attrs: {
        disabled: _vm.disabledProduct,
        type: _vm.name,
        active: index === _vm.activeIndex,
        "product-info": item
      },
      on: {
        click: function click($event) {
          return _vm.handleClickProduct(item, index);
        }
      }
    });
  }), _vm.proxyUser && _vm.productList.length > 0 || _vm.ispc && _vm.productList.length > 0 ? _c("div", {
    staticClass: "productItem",
    on: {
      click: _vm.TODDBatch
    }
  }, [_vm._m(4)]) : _vm._e()] : _vm.name == "POSTPAID" ? [_vm.flag === false && _vm.productList.length > 0 && _vm.currentTipsInfo == "" ? _c("div", {
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒: 请确认SIM卡在手机内并正常使用中 ")], 1) : _vm._e(), _vm.flag === false && _vm.productList.length > 0 && _vm.currentTipsInfo != "" ? _c("div", {
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒: 每笔话费充值，" + _vm._s(_vm.displayCname) + "政府均收取" + _vm._s(_vm.currentTipsInfo.taxValue) + "%税款 ")], 1) : _vm._e(), _vm._l(_vm.productList, function (item, index) {
    return _c("product-item", {
      key: item.code,
      attrs: {
        disabled: _vm.disabledProduct,
        type: _vm.name,
        active: index === _vm.activeIndex,
        "product-info": item
      },
      on: {
        click: function click($event) {
          return _vm.handleClickProduct(item, index);
        }
      }
    });
  }), _vm.proxyUser && _vm.productList.length > 0 || _vm.ispc && _vm.productList.length > 0 ? _c("div", {
    staticClass: "productItem",
    on: {
      click: _vm.TODDBatch
    }
  }, [_vm._m(5)]) : _vm._e()] : _vm.name == "TC" ? [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === false && _vm.TcProductList.length > 0,
      expression: "flag === false && TcProductList.length > 0"
    }],
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒: 套餐充值前,请确保有话费余额。 ")], 1), _vm._l(_vm.TcProductList, function (item, index) {
    return _c("Product-Item-TC", {
      key: index,
      attrs: {
        disabled: _vm.disabledProduct,
        type: _vm.name,
        active: index === _vm.activeIndex,
        "product-info": item
      },
      on: {
        click: function click($event) {
          return _vm.handleClickProduct(item, index);
        }
      }
    });
  }), _vm.proxyUser && _vm.TcProductList.length > 0 || _vm.ispc && _vm.TcProductList.length > 0 ? _c("div", {
    staticClass: "productItem",
    on: {
      click: _vm.TODDBatch
    }
  }, [_vm._m(6)]) : _vm._e()] : _vm._e()]], 2), _c("van-swipe", {
    staticClass: "my-swipe",
    attrs: {
      autoplay: 2500,
      "indicator-color": "white"
    }
  }, _vm._l(_vm.bannersList, function (item, index) {
    return _c("van-swipe-item", {
      key: index,
      staticStyle: {
        height: "100px"
      }
    }, [_vm.wxBrowser && item.wxMiniProgramUrl && /^\/pages/.test(item.wxMiniProgramUrl) && item.clickUrl == null ? _c("wx-open-launch-weapp", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        id: "launch-btn",
        appid: "wx7ea0d9f2e9925009",
        path: "pages/index/index"
      }
    }, [_c("script", {
      attrs: {
        type: "text/wxtag-template"
      }
    }, [_vm._v(' <img src="' + _vm._s(item.imgUrl) + '" style="vertical-align: baseline;-webkit-user-drag: none;height:100px;width:100%;"/> ')])]) : _c("img", {
      staticStyle: {
        "vertical-align": "baseline",
        "-webkit-user-drag": "none"
      },
      attrs: {
        src: item.imgUrl
      },
      on: {
        click: function click($event) {
          return _vm.clickSwiper(item);
        }
      }
    })], 1);
  }), 1), _vm.displayCarrierName !== "请选择运营商" ? _c("div", {
    staticStyle: {
      padding: "10px 4%",
      "line-height": "18px",
      color: "#a6a6a6"
    }
  }, [_c("p", [_vm._v("客户须知：")]), _vm.pinShow ? _c("p", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" 该产品为充值卡产品，购买成功会将会收到兑换信息，需自行手动兑换； ")]) : _vm._e(), _c("p", [_vm._v("1.充值前请仔细核对手机号码；")]), _c("p", [_vm._v(" 2.充值前确保手机卡在正常使用状态下（如：手机卡已插入手机内，手机卡有信号等）； ")]), _c("p", [_vm._v("3.充值流量前请确保手机卡内有充足话费余额；")]), _c("p", [_vm._v("4.流量产品没有特别标注为国际漫游流量都为本地流量包，只允许在充值国家或地区使用；")]), _c("p", [_vm._v("5.充值过程遇到问题，请及时联系客服处理；")]), _c("p", [_vm._v("未遵循以上说明导致的充值问题，无法退款。")])]) : _vm._e(), _c("zch-wait-order", {
    attrs: {
      "wait-order-visible": _vm.waitOrderVisible,
      "wait-order-info": _vm.waitOrderInfo
    },
    on: {
      close: function close($event) {
        _vm.waitOrderVisible = $event;
      },
      showOrder: function showOrder($event) {
        _vm.orderVisible = $event;
      },
      showMember: function showMember($event) {
        _vm.memberVisible = $event;
      }
    }
  }), _c("zch-order", {
    attrs: {
      "order-visible": _vm.orderVisible,
      "pre-order-info": _vm.preOrderInfo,
      "order-detail": _vm.orderDetail,
      "beach-recharge-bol": _vm.beachRechargeBol,
      deepCopyUsedPhoneList: _vm.deepCopyUsedPhoneList,
      displayCname: _vm.displayCname
    },
    on: {
      close: function close($event) {
        _vm.orderVisible = $event;
      },
      payment: function payment($event) {
        _vm.orderVisible = $event;
      },
      showMember: function showMember($event) {
        _vm.memberVisible = $event;
      },
      showDiaLog: _vm.showDiaLog,
      setOrderVisibleFalse: _vm.setOrderVisibleFalse
    }
  }), _c("van-dialog", {
    staticClass: "my_dialog",
    attrs: {
      "get-container": "body",
      title: "注意事项",
      showConfirmButton: false,
      "confirm-button-text": "关闭",
      "confirm-button-color": "#000",
      "message-align": "left"
    },
    on: {
      confirm: _vm.exchangeAlertInfoConfirm,
      close: _vm.closePinDialog
    },
    model: {
      value: _vm.pinVisiable,
      callback: function callback($$v) {
        _vm.pinVisiable = $$v;
      },
      expression: "pinVisiable"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "20px 20px 60px",
      "font-size": "14px",
      color: "#656667",
      position: "relative"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "25px"
    }
  }, [_c("div", [_vm._v("注意，该产品为卡密产品，需手动兑换后到账")]), _c("p", [_vm._v("1.  请确认运营商和号码正确")]), _c("p", [_vm._v("2.  请确认电话卡是正常使用中的状态")]), _c("p", [_vm._v("3.  卡密产品需手动兑换，请确保手机卡在您手中")]), _c("p", [_vm._v("4.  人不在当地不要充")]), _c("p", [_vm._v("5.  不知道卡密是什么，不知道怎么使用，请咨询客服")]), _c("p", [_vm._v("6.  后付费卡不要充")]), _c("div", [_vm._v("如有疑问，请先咨询客服了解情况后进行充值")]), _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*请遵循以上说明，一旦卡密到账，不予退款。")])]), _vm.pinStudyNewsId ? _c("div", {
    staticClass: "skipBtnDetail",
    on: {
      click: _vm.skipToImageTextDetailPage
    }
  }, [_c("img", {
    staticClass: "skipBtnDetailIcon",
    attrs: {
      src: require("@/assets/home/exclaimOver.png"),
      width: "24px"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      "margin-left": "5px",
      "font-size": "16px"
    }
  }, [_vm._v("卡密兑换教程")])]) : _vm._e(), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "margin-top": "20px",
      "margin-bottom": "10px"
    }
  }, [_c("van-checkbox", {
    attrs: {
      shape: "square"
    },
    on: {
      click: _vm.changeCheckbox2
    },
    model: {
      value: _vm.exchangeIsChecked,
      callback: function callback($$v) {
        _vm.exchangeIsChecked = $$v;
      },
      expression: "exchangeIsChecked"
    }
  }, [_vm._v("我已同意并知晓")])], 1), _vm.countDown > 0 ? _c("div", {
    staticClass: "closeBtn closeBtn2"
  }, [_vm._v("倒计时" + _vm._s(_vm.countDown) + "秒")]) : _vm._e(), _vm.countDown === 0 ? _c("div", {
    staticClass: "closeBtn",
    staticStyle: {
      width: "50%"
    },
    on: {
      click: _vm.exchangeAlertInfoConfirm
    }
  }, [_vm._v(" 确认 ")]) : _vm._e(), _vm.countDown === 0 ? _c("div", {
    staticClass: "closeBtnCancel",
    on: {
      click: function click($event) {
        _vm.exchangeIsChecked = false;
        _vm.pinVisiable = false;
      }
    }
  }, [_vm._v(" 取消 ")]) : _vm._e()])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batchItem"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "900"
    }
  }, [_vm._v("批量充值入口"), _c("br")]), _c("span", {
    staticStyle: {
      "padding-top": "5px",
      "font-size": "12px"
    }
  }, [_vm._v("多号码一次提交")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batchItem"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "900"
    }
  }, [_vm._v("批量充值入口"), _c("br")]), _c("span", {
    staticStyle: {
      "padding-top": "5px",
      "font-size": "12px"
    }
  }, [_vm._v("多号码一次提交")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batchItem"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "900"
    }
  }, [_vm._v("批量充值入口"), _c("br")]), _c("span", {
    staticStyle: {
      "padding-top": "5px",
      "font-size": "12px"
    }
  }, [_vm._v("多号码一次提交")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batchItem"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "900"
    }
  }, [_vm._v("批量充值入口"), _c("br")]), _c("span", {
    staticStyle: {
      "padding-top": "5px",
      "font-size": "12px"
    }
  }, [_vm._v("多号码一次提交")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batchItem"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "900"
    }
  }, [_vm._v("批量充值入口"), _c("br")]), _c("span", {
    staticStyle: {
      "padding-top": "5px",
      "font-size": "12px"
    }
  }, [_vm._v("多号码一次提交")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batchItem"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "900"
    }
  }, [_vm._v("批量充值入口"), _c("br")]), _c("span", {
    staticStyle: {
      "padding-top": "5px",
      "font-size": "12px"
    }
  }, [_vm._v("多号码一次提交")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batchItem"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "900"
    }
  }, [_vm._v("批量充值入口"), _c("br")]), _c("span", {
    staticStyle: {
      "padding-top": "5px",
      "font-size": "12px"
    }
  }, [_vm._v("多号码一次提交")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };