var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-item"
  }, [_c("img", {
    staticClass: "cover-image",
    attrs: {
      src: _vm.images,
      alt: "Cover Image"
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };