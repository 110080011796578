var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "timer"
  }, [_c("van-swipe", {
    ref: "mySwiper",
    staticClass: "my-swipe",
    attrs: {
      "indicator-color": "white"
    },
    on: {
      change: _vm.onChange
    },
    scopedSlots: _vm._u([_vm.images.length !== 1 || _vm.images.length !== 0 ? {
      key: "indicator",
      fn: function fn() {
        return [_c("div", {
          staticClass: "leftArrow",
          on: {
            click: function click($event) {
              return _vm.clickArrow("left");
            }
          }
        }), _c("div", {
          staticClass: "rightArrow",
          on: {
            click: function click($event) {
              return _vm.clickArrow("right");
            }
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }, _vm._l(_vm.images, function (image, index) {
    return _c("van-swipe-item", {
      key: index
    }, [_c("img", {
      directives: [{
        name: "lazy",
        rawName: "v-lazy",
        value: image,
        expression: "image"
      }]
    })]);
  }), 1), _vm._m(0)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "saveButton"
  }, [_c("span", [_vm._v("长按保存图片，去朋友圈秀一秀吧～")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };