var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "shareQrCode"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.shareQrCodeImg,
      alt: ""
    }
  }), _c("div", {
    staticClass: "desc",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("长按识别图片二维码")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v("有效期至 : " + _vm._s(_vm.myEndTime))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };