var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("van-popup", {
    staticClass: "carrierWrapper",
    style: _vm.carrierList.length > 8 ? {
      height: "80%"
    } : {},
    attrs: {
      closeable: "",
      "safe-area-inset-bottom": "",
      position: "bottom"
    },
    model: {
      value: _vm._carrierVisible,
      callback: function callback($$v) {
        _vm._carrierVisible = $$v;
      },
      expression: "_carrierVisible"
    }
  }, [_c("h4", [_vm._v("请选择运营商")]), _c("ul", {
    staticClass: "carrierWrapper-carrierList"
  }, _vm._l(_vm.carrierList, function (item) {
    return _c("li", {
      key: item.carrierName,
      staticClass: "carrierWrapper-carrierList-item",
      on: {
        click: function click($event) {
          return _vm.$emit("selectedCarrier", item);
        }
      }
    }, [_c("div", {
      staticClass: "carrierWrapper-carrierList-item-left"
    }, [_c("div", {
      staticClass: "carrierWrapper-carrierList-item-img"
    }, [_c("img", {
      attrs: {
        width: "100%",
        src: item.carrierImgUrl + "?x-oss-process=image/resize,w_100"
      }
    })]), _vm._v(" " + _vm._s(item.carrierName) + " ")]), _vm.selectedCarrierName === item.carrierName ? _c("zch-icon", {
      staticClass: "fill",
      attrs: {
        icon: "circleRight"
      }
    }) : _c("zch-icon", {
      attrs: {
        icon: "circle"
      }
    })], 1);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };