import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.function.name.js";
import Item from '../ZchPopupCoupon';
export default {
  name: 'ZchCoupon',
  components: _defineProperty(_defineProperty(_defineProperty({
    Item: Item
  }, _Dialog.name, _Dialog), _RadioGroup.name, _RadioGroup), _Radio.name, _Radio),
  props: {
    couponDetail: Object,
    couponList: Array
  },
  data: function data() {
    return {
      radio: ''
    };
  },
  watch: {
    couponDetail: {
      immediate: true,
      handler: function handler() {
        this.radio = this.couponDetail.uuid;
      }
    }
  }
};