import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
// 处理国家信息
export function handleUpdateCountryInfo(updateCountryList) {
  var currentCountryList = JSON.parse(sessionStorage.getItem('flags'));
  console.log(updateCountryList, "更新..");
  var list = [];
  updateCountryList.map(function (item) {
    var obj = {
      an: item.areaNumber,
      cn: item.cname,
      cc: item.countryCode
    };
    list.push(obj);
  });
  console.log(list, '..');
  var newCountry = [];
  list.map(function (item) {
    var flag = 0;
    currentCountryList.map(function (i, index) {
      if (item.cc == i.cc) {
        console.log(item, '//');
        currentCountryList[index] = item;
        flag = 1;
      }
    });
    if (flag == 0) {
      newCountry.push(item);
    }
  });
  for (var i in newCountry) {
    currentCountryList.push(newCountry[i]);
  }
  var resultList = currentCountryList;
  sessionStorage.setItem("flags", JSON.stringify(resultList));
  // 存储的最新的数据 把这些上传到oss上即可..
  localStorage.setItem("newFlags", JSON.stringify(resultList));
}