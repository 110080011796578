import "core-js/modules/es.array.concat.js";
import requestWithLoading from '@/utils/requestWithLoading';
export function getMonthlyRanking() {
  return requestWithLoading({
    url: '/wx/member/monthlyRanking',
    method: 'GET'
  });
}
export function getShareRecordList() {
  return requestWithLoading({
    url: '/wx/member/inviteFriend',
    method: 'get'
  });
}

//获取ticket票据凭证
export function getTicket(source, appId) {
  return requestWithLoading({
    url: "/wx/member/getShareCodeInfo?source=".concat(source, "&appId=").concat(appId),
    method: 'post'
  });
}
//获取用户推广的统计数据
export function getUserShareStatistics() {
  return requestWithLoading({
    url: "/wx/member/getUserShareStatistics",
    method: 'post'
  });
}