var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "paySuccess"
  }, [_c("img", {
    staticClass: "paySuccessImg",
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/paySuccess1.png",
      alt: "支付成功图标"
    }
  }), _c("h2", [_vm._v("支付成功")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v("您已完成支付")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v("充值状态可在订单详情页查看")]), _c("div", {
    staticClass: "detail-btn",
    on: {
      click: _vm.toBusiness
    }
  }, [_vm._v("查看订单详情")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };