import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  name: "ProductItem",
  props: {
    type: String,
    productInfo: Object,
    disabled: Boolean,
    active: Boolean
  },
  data: function data() {
    return {
      isFirst: "首次优惠",
      isRechargeCard: "充值卡",
      isBatch: false
    };
  },
  created: function created() {
    console.log("ProductltemLMouth , isFirstRechargeUser ---.>>>>>", this.isFirstRechargeUser);
    this.isBatch = RegExp(/batch\/recharge/).test(this.$route.path);
  },
  computed: {
    isFirstRechargeUser: function isFirstRechargeUser() {
      return this.$store.state.isFirstRechargeUser;
    }
  }
};