var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("van-popup", {
    staticClass: "codePayPopup",
    attrs: {
      "safe-area-inset-bottom": "",
      "close-on-click-overlay": false,
      "close-on-popstate": true,
      "get-container": "#app"
    },
    model: {
      value: _vm._codePayVisible,
      callback: function callback($$v) {
        _vm._codePayVisible = $$v;
      },
      expression: "_codePayVisible"
    }
  }, [_c("h1", {
    staticClass: "title"
  }, [_vm._v("微信支付")]), _vm.autoAskIndex === 0 ? _c("h3", {
    on: {
      click: _vm.handlePaySuccessClick
    }
  }, [_vm._v("我已完成支付")]) : _vm._e(), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countDownVisible,
      expression: "countDownVisible"
    }]
  }, [_vm._v("距离二维码过期还剩"), _c("em", [_c("van-count-down", {
    ref: "countDown",
    staticClass: "countDown",
    attrs: {
      "auto-start": false,
      time: _vm.time,
      format: "mm:ss"
    },
    on: {
      finish: function finish($event) {
        _vm.countDownVisible = false;
      }
    }
  })], 1), _vm._v("秒，过期后请刷新页面重新获取二维码。")]), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.countDownVisible,
      expression: "!countDownVisible"
    }]
  }, [_vm._v("二维码已过期，"), _c("em", {
    on: {
      click: function click($event) {
        _vm._codePayVisible = false;
      }
    }
  }, [_vm._v("返回")]), _vm._v(" 页面重新下单。")]), _c("div", {
    staticClass: "qrcode-img"
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.imgUrl
    }
  })]), _c("div", {
    staticClass: "qrcode-bottom"
  }, [_c("zch-icon", {
    attrs: {
      icon: "scan"
    }
  }), _c("div", {
    staticClass: "qrcode-bottom-tips"
  }, [_c("p", [_vm._v("请使用微信扫一扫")]), _c("p", [_vm._v("扫描二维码支付")])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };