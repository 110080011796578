import CouponItem from '@/components/ZchCoupon/components/Item';
export default {
  name: 'CouponList',
  components: {
    CouponItem: CouponItem
  },
  props: {
    type: String,
    couponList: Array
  }
};