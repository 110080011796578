var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "Page404"
    }
  }, [_vm._m(0), _c("h1", [_vm._v("呀！页面找不到了！")]), _c("h2", [_vm._v("请返回到首页...")]), _c("router-link", {
    attrs: {
      to: "/home"
    }
  }, [_c("van-button", {
    staticClass: "return-button",
    attrs: {
      type: "default"
    }
  }, [_vm._v("回到首页")])], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "img-404"
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: require("../../assets/404/404.png"),
      alt: "404"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };